import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { IContact, IUser, IWorkType } from "../types/types";

export enum FilterEnum {
  LastYear = "LastYear",
  YTD = "YTD",
  LastMonth = "LastMonth",
  ThisMonth = "ThisMonth",
  Today = "Today",
  Custom = "Custom",
}

export enum SortEnum {
  Customer = "Customer",
  InvoiceNo = "InvoiceNo",
  InvoiceDate = "InvoiceDate",
  DueDate = "DueDate",
  Total = "Total",
  Balance = "Balance",
  AssignedUser = "AssignedUser",
  PaymentDate = "PaymentDate",
  Amount = "Amount",
  PaymentMethod = "PaymentMethod",
  Notes = "Notes",
  Status = "Status",
  PaymentStatus = "PaymentStatus",
  InvoiceStatus = "InvoiceStatus",
}

export interface Invoice {
  isIncluded: boolean;
  createdByUserId: string;
  assignedToUserId: string;
  status: string;
  createdByUser: IUser;
  assignedToUser: IUser;
  invoiceNo: number;
  total: number;
  discount: number;
  noOfCustomers: number;
  discountTypeId: string;
  paymentRecieved: number;
  tax: number;
  balance: number;
  description: null | string;
  invoiceDate: string;
  dueDate: string;
  isPayed: boolean;
  termId: string;
  term: { name: string };
  customerId: string;
  orgId: string;
  invoiceItem: InvoiceItem[];
  invoiceTerms: ITerm;
  customer: Customer;
  id: string;
  relayId: null | string;
  createdAt: string;
  sumOfBalance: number;
  sumOfTotal: number;
  invoiceCustomers: IInvoiceCustomers[];
  invoiceStatus: BillingStatusType | null;
  invoiceStatusId: string | null;
  invoiceClientsIds: string[];
  notes: string;
  imageURL: string | null;
}

export interface IInvoiceCustomers {
  id: string;
  customerId: string;
  invoiceId: string;
  customers: { name: string; businessContactId?: string; contactId?: string };
}

export interface IPaymentItem {
  id: string;
  relayId: string;
  createdAt: string;
  amountPaid: number;
  invoiceTotal: number;
  invoiceNo: number;
  invoiceDueDate: string;
  paymentId: string;
  invoiceId: string;
  invoiceBalance: number;
}

export interface IPayment {
  createdByUserId: string;
  createdByUser: IUser;
  amount: number;
  notes: null | string;
  paymentDate: string;
  customerId: string;
  method: string;
  orgId: string;
  paymentMethodId: string;
  customer: Customer;
  paymentMethod: { paymentMethod: string; id: string };
  id: string;
  relayId: null | string;
  sumOfAmount?: number;
  createdAt: string;
  paymentStatus: BillingStatusType | null;
  paymentStatusId: string | null;
  paymentItem: IPaymentItem[];
  imageURL: string | null;
}

interface Customer {
  name: string;
  customerTypeEnumId: string;
  contactId: string;
  businessContactId: null | string;
  locationId: string;
  id: string;
  relayId: null | string;
  createdAt: string;
  addresses: ICustomerAddress[];
}

interface ICustomerAddress {
  city: string;
  region: string;
  country: string | null;
  line1: string;
  line2: string | null;
  postalCode: string;
  customerId: string;
  addresTypeEnumId: string | null;
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface InvoiceItem {
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  billingServiceId: string;
  billingService: { id: string; name: string };
  invoiceId: string;
  createdByUserId: null | string;
  id: string;
  relayId: null | string;
  createdAt: string;
}

export interface IAddInvoice {
  isIncluded: boolean;
  notes: string;
  customerId: string;
  assignedToUserId: string | null;
  createdByUserId: string;
  invoiceDate: string;
  termId: string;
  dueDate: string;
  invoiceNo: number;
  tax: number;
  orgId: string;
  discount: number;
  discountTypeId: string;
  total: number;
  invoiceItem: IAddInvoiceItem[];
  invoiceCustomers: { customerId: string }[];
}
export interface IEditInvoice {
  isIncluded: boolean;
  id: string;
  customerId: string;
  notes: string;
  invoiceClientsIds: string[] | null;
  createdByUserId: string;
  invoiceDate: string;
  termId: string;
  dueDate: string;
  invoiceNo: number;
  tax: number;
  orgId: string;
  total: number;
  discount: number;
  balance: number;
  discountTypeId: string;
  invoiceStatusId: string | null;
  paymentRecieved: number;
  assignedToUserId: string | null;
  invoiceItem: IAddInvoiceItem[];
  invoiceCustomers: { customerId: string }[];
}

interface IAddInvoiceItem {
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  billingServiceId: string;
  id?: string;
  invoiceId?: string;
}

export interface ITerm {
  termName: string;
  id: string;
  relayId: null | string;
  createdAt: string;
}

export interface IAddPayment {
  paymentDate: string;
  amount: number;
  description?: string;
  balance: number;
  notes: string;
  orgId: string;
  paymentMethodId: string;
  customerId: string;
  userId: string;
  paymentItem: { invoiceId: string; amountPaid: number }[];
}

export interface IUpdatePayment {
  paymentDate: string;
  amount: number;
  id: string;
  description?: string;
  balance: number;
  notes: string;
  paymentMethodId: string;
  paymentStatusId: string | null;
  customerId: string;
  paymentItem: { invoiceId: string; amountPaid: number; id: string }[];
}

export interface IService {
  name: string;
  description: string;
  rate: number;
  orgId: string;
  createdAt: string;
  id: string;
}

export interface IAddService {
  name: string;
  description: string;
  rate: number;
  orgId: string;
}

export interface IUpdateService extends IAddService {
  id: string;
}

export interface IPaymentMethod {
  paymentMethod: string;
  id: string;
  relayId: string | null;
  createdAt: string;
}

export interface ITaxRate {
  taxRate: number;
  id: string;
  taxName: string | null;
  createdAt: string;
  orgId: string;
}
export interface IIvoiceStats {
  totalInvoices: number;
  totalOutStandings: number;
  totalPastDue: number;
}

export type BillingLogo = {
  imageURI: string;
};

export type BillingStatusType = {
  id: string;
  relayId: string;
  createdAt: Date;
  name: string;
  description: string;
  color: string;
  order: number;
  orgId: string;
};

export const billingAPI = createApi({
  reducerPath: "billingAPI",
  tagTypes: [
    "Invoices",
    "Payment",
    "PaymentMethods",
    "Services",
    "TaxRate",
    "Logo",
    "Invoice_Statuses",
    "Payment_Statuses",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getInvoices: build.query<
      Invoice[],
      {
        orgId: string;
        filterByDate: string[];
        fromDate?: string | null;
        toDate?: string | null;
        sortOrder?: "desc" | "asc";
        sortBy?: SortEnum;
        filterByStatus: string[];
        filterByClient: string[];
        filterByAssignee: string[];
        filterByLabel: string[];
      }
    >({
      query: (body) => ({
        url: "api/invoice/filter-invoice",
        body,
        method: "POST",
      }),
      providesTags: ["Invoices"],
    }),
    getInvoiceById: build.query<Invoice, string>({
      query: (invoiceId = "") => ({
        url: "api/invoice/get-by-id/" + invoiceId,
      }),
      providesTags: ["Invoices"],
    }),
    getPaymentsRecieved: build.query<number, string>({
      query: (invoiceId = "") => ({
        url: "api/invoice/payments-recieved/" + invoiceId,
      }),
      providesTags: ["Invoices", "Payment"],
    }),
    getInvoicesByCustomer: build.query<Invoice[], string>({
      query: (customerId = "") => ({
        url: "api/invoice/get-by-customer/" + customerId,
      }),
      providesTags: ["Invoices"],
    }),
    getInvoiceTerms: build.query<ITerm[], void>({
      query: () => ({
        url: "api/invoice/get-invoice-terms/",
      }),
    }),
    getInvoiceStats: build.query<IIvoiceStats, string>({
      query: (orgId) => ({
        url: "api/invoice/get-invoice-amounts/" + orgId,
      }),
      providesTags: ["Invoices"],
    }),
    getInvoiceNo: build.query<number, string>({
      query: (orgId) => ({
        url: "api/invoice/get-invoice-no/" + orgId,
      }),
    }),
    getWorkTypes: build.query<IWorkType[], string>({
      query: (orgId) => ({
        url: "api/services/get-all",
        params: {
          orgId,
        },
      }),
    }),
    getServices: build.query<IService[], string>({
      query: (orgId) => ({
        url: "api/invoice-services/get-all/" + orgId,
      }),
      providesTags: ["Services"],
    }),
    addService: build.mutation<unknown, IAddService>({
      query: (body) => ({
        url: "api/invoice-services/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Services"],
    }),
    updateService: build.mutation<unknown, IUpdateService>({
      query: (body) => ({
        url: "api/invoice-services/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Services"],
    }),
    deleteService: build.mutation<unknown, string>({
      query: (id) => ({
        url: "api/invoice-services",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["Services"],
    }),
    addInvoice: build.mutation<Invoice, IAddInvoice>({
      query: (body) => ({
        url: "api/invoice/add-invoice",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invoices"],
    }),
    addInvoiceItem: build.mutation<IAddInvoiceItem, IAddInvoiceItem>({
      query: (body) => ({
        url: "api/invoice-item/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invoices"],
    }),
    deleteInvoiceItem: build.mutation<unknown, string>({
      query: (id) => ({
        url: "api/invoice-item",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["Invoices"],
    }),
    updateInvoice: build.mutation<unknown, IEditInvoice | any>({
      query: (body) => ({
        url: "api/invoice/update-invoice",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Invoices"],
    }),
    deleteInvoice: build.mutation({
      query: (body: Invoice) => ({
        url: "api/invoice",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Invoices"],
    }),
    getPayments: build.query<
      IPayment[],
      {
        orgId: string;
        filterByDate: string[];
        fromDate?: string | null;
        toDate?: string | null;
        sortBy?: SortEnum;
        sortOrder?: "desc" | "asc";
        filterByStatus: string[];
        filterByClient: string[];
        filterByAssignee: string[];
        filterByLabel: string[];
      }
    >({
      query: (body) => ({
        url: "api/payment/filter-payment",
        method: "POST",
        body,
      }),
      providesTags: () => ["Payment"],
    }),
    getPaymentById: build.query<IPayment, string>({
      query: (id) => ({
        url: "api/payment/get-by-id/" + id,
        method: "GET",
      }),
      providesTags: () => ["Payment"],
    }),
    getPaymentAmounts: build.query<{ totalPayments: number }, string>({
      query: (orgId) => ({
        url: "api/payment/get-payment-amounts/" + orgId,
        method: "GET",
      }),
      providesTags: () => ["Payment"],
    }),
    getPaymentByInvoiceId: build.query<IPayment[], string>({
      query: (invoiceId) => ({
        url: "api/payment/payments-by-invoice/" + invoiceId,
        method: "GET",
      }),
      providesTags: () => ["Payment"],
    }),
    getPaymentMethods: build.query<IPaymentMethod[], string>({
      query: (orgId) => ({
        url: "api/payment-methods/get-all/" + orgId,
      }),
      providesTags: ["PaymentMethods"],
    }),
    addPaymentMethod: build.mutation<
      void,
      { paymentMethod: string; orgId: string }
    >({
      query: (body) => ({
        url: "api/payment-methods/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    updatePaymentMethod: build.mutation<
      void,
      { paymentMethod: string; id: string }
    >({
      query: (body) => ({
        url: "api/payment-methods/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PaymentMethods"],
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
    }),
    deletePaymentMethod: build.mutation<void, string>({
      query: (id) => ({
        url: "api/payment-methods",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    addPayment: build.mutation({
      query: (body: IAddPayment) => ({
        url: "api/payment/add-payment",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payment", "Invoices"],
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
    }),
    updatePayment: build.mutation<unknown, IUpdatePayment | any>({
      query: (body) => ({
        url: "api/payment/update-payment",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Payment", "Invoices"],
    }),
    deletePayment: build.mutation({
      query: (body: IPayment) => ({
        url: "api/payment",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Payment", "Invoices"],
    }),
    getTaxRate: build.query<ITaxRate[], string>({
      query: (orgId) => ({
        url: "api/tax-rate/get-all/" + orgId,
      }),
      providesTags: () => ["TaxRate"],
    }),
    addTaxRate: build.mutation<
      void,
      { taxName: string; taxRate: number; orgId: string }
    >({
      query: (body) => ({
        url: "api/tax-rate/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["TaxRate"],
    }),
    updateTaxRate: build.mutation<void, ITaxRate>({
      query: (body) => ({
        url: "api/tax-rate/update",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TaxRate"],
    }),
    deleteTaxRate: build.mutation<void, ITaxRate>({
      query: (body) => ({
        url: "api/tax-rate",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["TaxRate"],
    }),
    getContactById: build.query<IContact, { id: string; orgId: string }>({
      query: ({ id, orgId }) => ({
        url: `api/contacts/get/${id}?orgId=${orgId}`,
        method: "GET",
      }),
      providesTags: ["Payment"],
    }),
    getBillingLogo: build.query<BillingLogo, string>({
      query: (id) => ({
        url: `api/firm-logo/get-by-firm/${id}`,
        method: "GET",
      }),
      providesTags: ["Logo"],
    }),
    addBillingLogo: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/firm-logo/add-logo",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Logo"],
    }),
    updateBillingLogo: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/firm-logo/update-logo",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Logo"],
    }),
    deleteBillingLogo: build.mutation<void, string>({
      query: (orgId) => ({
        url: `api/firm-logo?orgId=${orgId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Logo"],
    }),
    getInvoiceStatuses: build.query<BillingStatusType[], string>({
      query: (id) => ({
        url: `api/invoice-status/get-by-orgId/${id}`,
        method: "GET",
      }),
      providesTags: ["Invoice_Statuses"],
    }),
    getAllInvoiceStatuses: build.query<BillingStatusType[], string>({
      query: (id) => ({
        url: `api/invoice-status/get-all/${id}`,
        method: "GET",
      }),
      providesTags: ["Invoice_Statuses"],
    }),
    addInvoiceStatus: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/invoice-status/add-status",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Invoice_Statuses"],
    }),
    updateInvoiceStatus: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/invoice-status/update-status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Invoice_Statuses"],
    }),
    reorderInvoiceStatus: build.mutation<void, string[]>({
      query: (body) => ({
        url: "api/invoice-status/re-order-status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Invoice_Statuses"],
    }),
    deleteInvoiceStatus: build.mutation<void, string>({
      query: (id) => ({
        url: `api/invoice-status/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invoice_Statuses"],
    }),
    getPaymentStatuses: build.query<BillingStatusType[], string>({
      query: (id) => ({
        url: `api/payment-status/get-by-orgId/${id}`,
        method: "GET",
      }),
      providesTags: ["Payment_Statuses"],
    }),
    getAllPaymentStatuses: build.query<BillingStatusType[], string>({
      query: (id) => ({
        url: `api/payment-status/get-all/${id}`,
        method: "GET",
      }),
      providesTags: ["Payment_Statuses"],
    }),
    addPaymentStatus: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/payment-status/add-status",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payment_Statuses"],
    }),
    reorderPaymentStatus: build.mutation<void, string[]>({
      query: (body) => ({
        url: "api/payment-status/re-order-status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Payment_Statuses"],
    }),
    updatePaymentStatus: build.mutation<void, unknown>({
      query: (body) => ({
        url: "api/payment-status/update-status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Payment_Statuses"],
    }),
    deletePaymentStatus: build.mutation<void, string>({
      query: (id) => ({
        url: `api/payment-status/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment_Statuses"],
    }),
  }),
});
export const {
  //Invoices
  useGetInvoicesQuery,
  useGetInvoiceNoQuery,
  useAddInvoiceMutation,
  useDeleteInvoiceMutation,
  useGetInvoicesByCustomerQuery,
  useGetInvoiceTermsQuery,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useAddInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
  useGetInvoiceStatsQuery,
  useGetPaymentsRecievedQuery,
  //Payment
  useGetPaymentsQuery,
  useGetPaymentByInvoiceIdQuery,
  useGetPaymentByIdQuery,
  useGetPaymentAmountsQuery,
  useAddPaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
  //Payment methods
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  //Services
  useGetServicesQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  //Tax rate
  useGetTaxRateQuery,
  useAddTaxRateMutation,
  useUpdateTaxRateMutation,
  useDeleteTaxRateMutation,
  // useGetContactByIdQuery,
  //Logo
  useGetBillingLogoQuery,
  useAddBillingLogoMutation,
  useUpdateBillingLogoMutation,
  useDeleteBillingLogoMutation,
  //Invoice statuses
  useGetInvoiceStatusesQuery,
  useGetAllInvoiceStatusesQuery,
  useAddInvoiceStatusMutation,
  useUpdateInvoiceStatusMutation,
  useReorderInvoiceStatusMutation,
  useDeleteInvoiceStatusMutation,
  //Payment statuses
  useGetPaymentStatusesQuery,
  useGetAllPaymentStatusesQuery,
  useAddPaymentStatusMutation,
  useReorderPaymentStatusMutation,
  useUpdatePaymentStatusMutation,
  useDeletePaymentStatusMutation,
} = billingAPI;

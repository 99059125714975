import { ChevronDown } from "baseui/icon";
import React, { FC } from "react";

import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { StatusesDropdown } from "../../../../../works/dropdown/StatusesDropdown";
import { getStatusColor } from "../../../../../works/dropdown/utils";

type Props = {
  work: WorkDetailsModel;
};
export const WorkStatus: FC<Props> = ({ work }) => {
  return (
    <div>
      <div className={"mb-1 text-base text-gray-400"}>STATUS</div>
      {work?.jobStatus && (
        <StatusesDropdown
          workTypeId={work?.serviceId || ""}
          workId={work?.id || ""}
          positionType={"bottom"}
          triggerElement={
            <div
              className={
                "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
              }>
              <div
                className={"flex h-[10px] w-[10px] rounded-full"}
                style={{
                  backgroundColor: getStatusColor(work?.jobStatus?.name || ""),
                }}
              />
              <div className={"max-w-[140px] truncate"}>
                {(work?.jobSubStatus
                  ? work?.jobSubStatus?.subStatus
                  : work?.jobStatus?.name) || "No status"}
              </div>
              <ChevronDown size={16} />
            </div>
          }
        />
      )}
    </div>
  );
};

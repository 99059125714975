import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { WorkTaskModel } from "../factories/work/model/work-task.model";
import { contactsSlice } from "./ContactsSlice";
import { workSlice } from "./WorkSlice";

export const workTasksSlice = createApi({
  reducerPath: "workTasksSlice",
  tagTypes: ["WorkTask"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getWorkTasks: build.query<WorkTaskModel[], { workId: string }>({
      query: ({ workId }) => ({
        url: "api/work-tasks/get-by-jobId/" + workId,
        method: "GET",
      }),
      transformResponse: (res: WorkTaskModel[]) => {
        return res?.sort((a, b) => (a?.order || 0) - (b?.order || 0));
      },
      providesTags: ["WorkTask"],
    }),
    addWorkTask: build.mutation<
      unknown,
      {
        jobIdBase64: string;
        assignedUserId: string;
        name: string;
        description: string;
      }
    >({
      query: (body) => ({
        url: "api/work-tasks/add-task",
        method: "POST",
        body,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
          api.dispatch(workSlice.util?.invalidateTags(["Work"]));
        });
      },
      invalidatesTags: ["WorkTask"],
    }),
    reorderWorkTasks: build.mutation<unknown, string[]>({
      query: (body) => ({
        url: "api/work-tasks/change-order",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkTask"],
    }),
    updateWorkTask: build.mutation<unknown, WorkTaskModel>({
      query: (body) => ({
        url: "api/work-tasks/update-task",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkTask"],
    }),
    reassignWorkTask: build.mutation<
      unknown,
      {
        name: string;
        id: string;
        jobId: string;
        assignedUserId: string;
        assignedByUserId: string;
      }
    >({
      query: (body) => ({
        url: "api/work-tasks/reassign-task",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["WorkTask"],
    }),
    updateWorkTaskStatus: build.mutation<
      WorkTaskModel,
      {
        taskId: string;
        isDone: boolean;
      }
    >({
      query: ({ taskId, isDone }) => ({
        url: `api/work-tasks/${taskId}/${isDone}`,
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
          api.dispatch(workSlice.util?.invalidateTags(["Work"]));
        });
      },
      invalidatesTags: ["WorkTask"],
    }),
    markDoneAllWorkTasks: build.mutation<unknown, { workId: string }>({
      query: ({ workId }) => ({
        url: "api/work/mark-as-completed/" + workId,
        method: "PUT",
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
          api.dispatch(workSlice.util?.invalidateTags(["Work"]));
        });
      },
      invalidatesTags: ["WorkTask"],
    }),
    deleteWorkTask: build.mutation<unknown, WorkTaskModel>({
      query: (body) => ({
        url: "api/work-tasks",
        method: "DELETE",
        body,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(contactsSlice.util?.invalidateTags(["ContactsWorks"]));
          api.dispatch(workSlice.util?.invalidateTags(["Work"]));
        });
      },
      invalidatesTags: ["WorkTask"],
    }),
  }),
});

export const {
  useGetWorkTasksQuery,
  useAddWorkTaskMutation,
  useReorderWorkTasksMutation,
  useUpdateWorkTaskMutation,
  useUpdateWorkTaskStatusMutation,
  useMarkDoneAllWorkTasksMutation,
  useReassignWorkTaskMutation,
  useDeleteWorkTaskMutation,
} = workTasksSlice;

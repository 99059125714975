import React, { ChangeEvent, ReactNode, RefObject } from "react";
import { HiPlus } from "react-icons/hi2";

import Button from "../../../components/button/Button";
import { CgAttachment } from "react-icons/cg";

type Props = {
  setFile: (file: File) => void;
  setError?: (error: string) => void;
  inputRef: RefObject<HTMLInputElement>;
  isLoading?: boolean;
  isEmailPage?: boolean;
  accept?: string;
  button?: ReactNode;
};

const maxFileSize = 30 * 1000 * 1000;

export const InputFile: React.FC<Props> = ({
  setFile,
  setError,
  inputRef,
  isLoading,
  isEmailPage,
  accept,
  button,
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      if (selectedFile.size > maxFileSize) {
        setError && setError("The file size exceeds the limit of 30mb");
        event.target.value = "";
      } else {
        setFile(event.target.files[0]);
        setError && setError("");
      }
    }
  };

  return (
    <div>
      <input
        ref={inputRef}
        type={"file"}
        accept={
          accept ||
          ".pdf,.xls,.xlsx,.doc,.docx,image/*,.ppt,.pptx,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint.presentation"
        }
        className={"absolute left-[-9999px] top-[-9999px]"}
        onChange={handleFileChange}
      />
      {isEmailPage ? (
        <CgAttachment onClick={() => inputRef?.current?.click()} />
      ) : button ? (
        <div onClick={() => inputRef?.current?.click()}>{button}</div>
      ) : (
        <Button
          label={""}
          icon={<HiPlus />}
          size={"custom"}
          colorType={"outline"}
          extraClasses={"btn-sm px-2"}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => inputRef?.current?.click()}
        />
      )}
    </div>
  );
};

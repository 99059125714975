import React, { FC, useEffect, useState } from "react";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { useParams } from "react-router-dom";

import Button from "../../../../../../components/button/Button";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../components/NoItems";
import ContactWorkSvg from "../../../../../../components/svg/ContactWorkSvg";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { useGetUsersQuery } from "../../../../../../slices/UserSlice";
import {
  useGetWorkTasksQuery,
  useReorderWorkTasksMutation,
} from "../../../../../../slices/WorkTasksSlice";
import { WorkTaskModal } from "../../../../../work/modals/WorkTaskModel";
import { WorkTask } from "./WorkTask";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { WorkTaskModel } from "../../../../../../factories/work/model/work-task.model";

const reorder = (
  list: WorkTaskModel[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const WorkTasksList: FC = () => {
  const { workId } = useParams();

  const { data, isLoading } = useGetWorkTasksQuery({
    workId: workId || "",
  });
  const [reorderWorkTasks] = useReorderWorkTasksMutation();

  const [workTasks, setWorkTasks] = useState<WorkTaskModel[]>(data || []);

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId,
  );

  const [isOpenAdd, setIsOpenAdd] = useState(false);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      workTasks,
      result.source.index,
      result.destination.index,
    );
    reorderWorkTasks(newItems.map((item) => item.id));
    setWorkTasks(newItems);
  };

  useEffect(() => {
    setWorkTasks(data || []);
  }, [data]);

  return (
    <>
      <div>
        {isLoading ? (
          <div>
            <LottieLoading />
          </div>
        ) : (
          <>
            {!workTasks?.length ? (
              <div className={"mt-8 px-6"}>
                <NoItems
                  icon={<ContactWorkSvg />}
                  message={"There are no subtasks"}
                  addButtonMessage={"Add Subtask"}
                  openAdd={() => setIsOpenAdd(true)}
                />
              </div>
            ) : (
              <div className={"px-6"}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={"droppable"}>
                    {(provided: DroppableProvided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={
                          "flex w-full flex-col gap-4 rounded-[7px] border border-gray-300 bg-gray-100 px-4 py-3"
                        }>
                        {workTasks?.map((workTask, index) => (
                          <Draggable
                            key={workTask.id}
                            draggableId={workTask.id}
                            index={index}>
                            {(provided: DraggableProvided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}>
                                <WorkTask
                                  workTask={workTask}
                                  users={users || []}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </>
        )}
        <div className={"w-full p-6"}>
          <Button
            label={"Add Subtask"}
            colorType={"ghost"}
            icon={<HiOutlinePlusCircle size={24} />}
            onClick={() => setIsOpenAdd(true)}
            extraClasses={"w-full border-purple text-purple"}
          />
        </div>
      </div>
      {isOpenAdd && (
        <WorkTaskModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
        />
      )}
    </>
  );
};

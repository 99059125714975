import { SelectOptionType } from "../../../../../../../../types/types";

export enum MonthsEnum {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export const monthsOptions: SelectOptionType[] = [
  { value: MonthsEnum.January, label: MonthsEnum.January },
  { value: MonthsEnum.February, label: MonthsEnum.February },
  { value: MonthsEnum.March, label: MonthsEnum.March },
  { value: MonthsEnum.April, label: MonthsEnum.April },
  { value: MonthsEnum.May, label: MonthsEnum.May },
  { value: MonthsEnum.June, label: MonthsEnum.June },
  { value: MonthsEnum.July, label: MonthsEnum.July },
  { value: MonthsEnum.August, label: MonthsEnum.August },
  { value: MonthsEnum.September, label: MonthsEnum.September },
  { value: MonthsEnum.October, label: MonthsEnum.October },
  { value: MonthsEnum.November, label: MonthsEnum.November },
  { value: MonthsEnum.December, label: MonthsEnum.December },
];

export const getDaysOptions = (month: MonthsEnum): SelectOptionType[] => {
  if (month === MonthsEnum.February) {
    return Array.from({ length: 28 }, (_, i) => ({
      label: i.toString(),
      value: i.toString(),
    }));
  }
  if (
    month === MonthsEnum.January ||
    month === MonthsEnum.March ||
    month === MonthsEnum.May ||
    month === MonthsEnum.July ||
    month === MonthsEnum.August ||
    month === MonthsEnum.October ||
    month === MonthsEnum.December
  ) {
    return Array.from({ length: 31 }, (_, i) => ({
      label: i.toString(),
      value: i.toString(),
    }));
  }
  return Array.from({ length: 30 }, (_, i) => ({
    label: i.toString(),
    value: i.toString(),
  }));
};

import { ChartData, ChartOptions } from "chart.js";
import React, { FC } from "react";
import { Doughnut } from "react-chartjs-2";

type Props = {
  totalTasks: number;
  completedTasks: number;
  totalDays: number;
  leftDays: number;
  dueIn: number;
};

const options: ChartOptions<"doughnut"> = {
  cutout: "83.3%",
  rotation: 180,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const WorkCharts: FC<Props> = ({
  totalTasks,
  completedTasks,
  totalDays,
  leftDays,
  dueIn,
}) => {
  const dataTasks: ChartData<"doughnut"> = {
    datasets: [
      {
        data: totalTasks ? [completedTasks, totalTasks - completedTasks] : [1],
        backgroundColor: totalTasks ? ["#FFD80B", "#e8e9ea"] : ["#e8e9ea"],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };

  const dataDates: ChartData<"doughnut"> = {
    datasets: [
      {
        data: totalDays ? [leftDays, totalDays - leftDays] : [1],
        backgroundColor: totalDays ? ["#47DE96", "#e8e9ea"] : ["#e8e9ea"],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div
      className={
        "grid h-[120px] grid-cols-[100px_max-content_100px] items-center gap-2"
      }>
      <div className={"relative"}>
        <Doughnut data={dataTasks} options={options} />
        <div
          className={
            "pointer-events-none absolute left-0 top-0 flex h-[100px] w-[100px] items-center justify-center text-xs font-bold"
          }>
          {completedTasks}/{totalTasks}
        </div>
      </div>
      <div className={"whitespace-nowrap text-sm font-semibold"}>
        {completedTasks} of {totalTasks} completed
      </div>
      <div className={"relative"}>
        <Doughnut data={dataDates} options={options} />
        <div
          className={
            "pointer-events-none absolute left-0 top-0 flex h-[100px] w-[100px] items-center justify-center text-xs font-bold"
          }>
          {dueIn} days
        </div>
      </div>
    </div>
  );
};

import { ChevronDown } from "baseui/icon";
import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { useUpdateStatusWorkMutation } from "../../../slices/WorkSlice";
import { StatusesList } from "./StatusesList";
import { getStatusColor } from "./utils";
import { showStatus } from "../../../constants/constants";

type Props = {
  workTypeId: string;
  workId: string;
  triggerElement: ReactNode;
  positionType?: "right" | "bottom";
};

export const StatusesDropdown: FC<Props> = ({
  workTypeId,
  workId,
  triggerElement,
  positionType = "right",
}) => {
  const [updateStatusWork, { isLoading: isLoadingUpdate, data: updatedWork }] =
    useUpdateStatusWorkMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [position, setPosition] = useState<CSSProperties | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const handleUpdateStatusWork = ({
    statusId,
    subStatusId,
  }: {
    statusId: string;
    subStatusId: string;
  }) => {
    updateStatusWork({
      workId: workId,
      statusId: statusId,
      subStatusId: subStatusId,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      const target = event.target;

      if (
        menuRef.current &&
        target instanceof Node &&
        !triggerRef.current?.contains(target) &&
        !menuRef.current.contains(target)
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = () => {
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen && triggerRef.current && menuRef.current) {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const menuHeight = menuRef.current?.offsetHeight;

      if (positionType === "bottom") {
        setPosition({
          top: triggerRect.bottom + 4,
          left: triggerRect?.left,
        });
      } else {
        setPosition({
          top: triggerRect.bottom - menuHeight,
          left: triggerRect?.right + 4,
        });
      }

      setIsHide(false);
    }

    if (!isOpen) {
      setIsHide(true);
    }
  }, [isOpen, triggerRef, menuRef, positionType]);

  const handleTriggerClick = () => {
    setIsOpen((prev) => !prev);
  };

  const portalElement = document.getElementById("portal-root");
  if (!portalElement) {
    return null;
  }

  return (
    <>
      <div onClick={handleTriggerClick} ref={triggerRef} className={"w-fit"}>
        {isLoadingUpdate ? (
          <div
            className={
              "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
            }>
            <div className={"text-purple h-[10px] w-[10px] animate-spin"}>
              <AiOutlineLoading3Quarters size={10} />
            </div>
            <div>Loading...</div>
            <ChevronDown size={16} />
          </div>
        ) : updatedWork ? (
          <div
            className={
              "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
            }>
            <div
              className={"flex h-[10px] w-[10px] rounded-full"}
              style={{
                backgroundColor: getStatusColor(updatedWork?.jobStatus || ""),
              }}
            />
            <div className={"max-w-[140px] truncate"}>
              {showStatus(updatedWork)}
            </div>
            <ChevronDown size={16} />
          </div>
        ) : (
          triggerElement
        )}
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "fixed",
              opacity: isHide ? 0 : 1,
              ...position,
              fontSize: "14px",
            }}
            ref={menuRef}>
            <StatusesList
              setIsOpen={setIsOpen}
              workTypeId={workTypeId}
              updateStatusWork={handleUpdateStatusWork}
            />
          </div>,
          portalElement,
        )}
    </>
  );
};

import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  useEditUserMutation,
  useGetUserRoleEnumQuery,
} from "../../../slices/UserSlice";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser } from "../../../types/types";
import { getInitials } from "../../../constants/constants";
import CustomSelect from "../../../components/select/Select";

type Props = {
  isActive: boolean;
  closeEdit: () => void;
  user: IUser;
};

export const EditUserView: React.FC<Props> = ({
  isActive,
  closeEdit,
  user,
}) => {
  const { data: userRoleEnum, isLoading } = useGetUserRoleEnumQuery();

  const [editUser, { isSuccess, isError }] = useEditUserMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Edit user");
  });

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      role: user?.userRoleEnum?.userRole,
    },
  });

  useEffect(() => {
    setValue("role", user?.userRoleEnumId);
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      closeEdit();
    }
  }, [isSuccess]);

  const onSubmit = (data: { role: string }) => {
    editUser({
      ...user,
      userRoleEnumId: data.role,
    });
  };

  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-fit max-w-[90%]"}>
        {isLoading ? (
          <div className={"max-w-[300px]"}>
            <LottieLoading />
          </div>
        ) : (
          <>
            <div className={"mb-4 text-base font-semibold text-gray-800"}>
              Edit user role
            </div>
            <div className={"mb-4 flex items-center"}>
              <AvatarPlaceholder
                size={"extra-small"}
                label={getInitials(
                  user?.userProfile?.firstName || "",
                  user?.userProfile?.lastName || "",
                )}
              />
              <div className={"flex flex-col pl-2"}>
                <div className={"text-[14px] capitalize"}>
                  {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={"form-control mb-8 w-full"}>
                <label className={"label"}>
                  <span className={"label-text"}>Preset roles:</span>
                </label>
                <select
                  {...register("role")}
                  className={"select select-bordered w-full"}>
                  {userRoleEnum?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.userRole}
                    </option>
                  ))}
                </select>
                <div>
                  <div className={"my-4 text-base font-semibold"}>
                    Permissions:
                  </div>
                  <div>
                    <div className={"grid grid-cols-2 items-center "}>
                      <div className={"mr-4 text-base"}>View settings</div>
                      <div className={"flex items-center"}>
                        <input
                          type="checkbox"
                          className={
                            "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]"
                          }
                        />
                      </div>
                    </div>
                    <div className={"grid grid-cols-2 items-center"}>
                      <div className={"mr-4 text-base"}>View billings</div>
                      <div className={"flex items-center"}>
                        <input
                          type="checkbox"
                          className={
                            "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]"
                          }
                        />
                      </div>
                    </div>
                    <div className={"grid grid-cols-2 items-center"}>
                      <div className={"mr-4 text-base"}>View firm work</div>
                      <div className={"flex items-center"}>
                        <input
                          type="checkbox"
                          className={
                            "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]"
                          }
                        />
                      </div>
                    </div>
                    <div className={"grid grid-cols-2 items-center"}>
                      <div className={"mr-4 text-base"}>View Dashboard</div>
                      <div className={"flex items-center"}>
                        <input
                          type="checkbox"
                          className={
                            "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={"my-4 text-base font-semibold"}>
                    Client access:
                  </div>
                  <div className={"grid grid-cols-2 items-center"}>
                    <div className={"mr-4 text-base"}>All contacts</div>
                    <div className={"flex items-center"}>
                      <input
                        type="checkbox"
                        className={
                          "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]"
                        }
                      />
                    </div>
                  </div>
                  <div className={"mb-1 mr-4 pl-1 text-base"}>Or</div>
                  <div>
                    <div
                      className={
                        "border-purple mr-4 h-[42px] w-[301px] rounded-md border"
                      }>
                      Select{" "}
                    </div>
                  </div>
                </div>
              </div>
              {isError && (
                <div className={"text-md mb-2 text-red-500"}>
                  Something went wrong
                </div>
              )}
              <div className={"flex justify-start gap-4"}>
                <Button
                  buttonType={"submit"}
                  label={"Update"}
                  colorType={"secondary"}
                  extraClasses={"normal-case"}
                />
                <OutlineButton
                  colorType={"neutral"}
                  label={"Cancel"}
                  onClick={closeEdit}
                  extraClasses={"normal-case"}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetArchivedCustomersQuery } from "../../../slices/ContactsSlice";
import { FormSection } from "../../../support/FormSection";
import { navigateToContact } from "../utils/navigateToContacts";
import TooltipDeactivatedMessage from "../components/TooltipDeactivatedMessage";

export const ArchivedContacts: FC = () => {
  const navigate = useNavigate();

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetArchivedCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      isActive: false,
      body: [],
    });

  const contactsCount: number = contacts?.length || 0;

  if (isLoadingContacts) {
    return <LottieLoading />;
  }

  return (
    <FormSection name={""} showHeading={false}>
      <div className={"text-lg font-bold"}>Archived ({contactsCount})</div>
      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Contact
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Phone Number
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Email Address
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Contact type
            </th>
            <th className={"p-4 text-start text-sm normal-case text-gray-400"}>
              Open work
            </th>
          </tr>
        </thead>
        <tbody>
          {contacts?.map((item) => (
            <tr
              key={item.id}
              className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
              <td className={"max-w-[200px] px-4 py-2"}>
                <div className={"flex items-center gap-3 text-sm"}>
                  <AvatarPlaceholder
                    type={item?.avatarColor}
                    size={"extra-small"}
                    label={item?.initials || ""}
                  />
                  <div
                    onClick={() =>
                      navigate(
                        navigateToContact({
                          type: item.type,
                          id: item?.businessContactId || "",
                          customerId: item?.id || "",
                        }),
                      )
                    }
                    className={
                      "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                    }>
                    {item.name}
                  </div>
                  <TooltipDeactivatedMessage />
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item.phoneNumber}
                </div>
              </td>
              <td className={"max-w-[200px] px-4 py-2"}>
                <div
                  className={
                    "link-primary max-w-[200px] cursor-pointer truncate text-sm font-semibold hover:underline"
                  }>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item.contactType}
                </div>
              </td>
              <td className={"px-4 py-2"}>
                <div
                  className={"text-sm font-semibold normal-case text-gray-800"}>
                  {item?.openWorkCounts}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FormSection>
  );
};

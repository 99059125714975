import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "usehooks-ts";

import Button from "../../../../../../components/button/Button";
import { Card } from "../../../../../../components/card/Card";
import CustomSelect from "../../../../../../components/select/Select";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../../../../constants/constants";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../../../../slices/ContactsSlice";
import { useGetUsersQuery } from "../../../../../../slices/UserSlice";
import {
  useUpdateWorkMutation,
  useUpdateWorkRepeatsMutation,
} from "../../../../../../slices/WorkSlice";
import { useGetWorkTypesQuery } from "../../../../../../slices/WorkTypeSlice";
import { repeats, SelectOptionType } from "../../../../../../types/types";
import { Repeat } from "../../../repeats/Repeats";
import { useAppSelector } from "../../../../../../redux/redux";

type Props = {
  work: WorkDetailsModel;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export type RepeatsData = {
  repeatOn: string;
  repeat: string;
  repeatCount: string;
  days: string;
  repeatUntil: Date;
  generatedDate: string | null;
  dueDate: string;
};

type FormData = {
  customer: SelectOptionType;
  workType: SelectOptionType;
  user: SelectOptionType;
};

export const EditWorkDetails: FC<Props> = ({ work, setIsEdit }) => {
  const { user } = useAppSelector((state) => state.appReducer);
  const [searchContacts, setSearchContacts] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const searchContactsDebounce = useDebounce(searchContacts, 300);
  const [repeatsData, setRepeatsData] = useState<RepeatsData>({
    days: work?.beforeDays.toString(),
    repeat: work?.repeatTypeEnumId,
    repeatCount: (work?.repeatValue || 0).toString(),
    repeatOn: work?.repeatOnId || "",
    generatedDate: null,
    repeatUntil: work?.repeatUntil ? new Date(work?.repeatUntil) : new Date(),
    dueDate: "1",
  });

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: searchContactsDebounce || "a",
      orgId: getLinkedFirm()?.orgId || "",
    });
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({
      orgId: getLinkedFirm()?.orgId || "",
    });
  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );

  const [
    updateDetails,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateWorkMutation();

  const [updateWorkRepeats] = useUpdateWorkRepeatsMutation();

  const { handleSubmit, control, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      customer: {
        label: work?.customer?.name,
        value: work?.customerId,
        secondValue: work?.customer?.businessContactId || "",
        avatar: getInitialsFromFullName(work?.customer?.name),
        avatarColor: work?.customer?.businessContactId ? "purple" : "blue",
      },
      user: {
        label:
          work?.assignedUser?.userProfile?.firstName +
          " " +
          work?.assignedUser?.userProfile?.lastName,
        value: work?.assignedUserId,
        avatar: getInitials(
          work?.assignedUser?.userProfile?.firstName,
          work?.assignedUser?.userProfile?.lastName,
        ),
        avatarColor: "blue",
      },
      workType: { value: work?.serviceId, label: work?.service?.name },
    },
  });

  const onSubmit = (data: FormData) => {
    if (isLoadingUpdate || isOpen) {
      return;
    }
    updateDetails({
      workId: work?.id,
      workName: work?.name,
      serviceId: data?.workType?.value,
      taskTemplateId: work?.templateId,
      userId: data?.user?.value,
      customerId: data?.customer?.value,
      businessContactId: data?.customer?.secondValue || null,
      isReassigned: work?.isReassigned,
      assignedByUserId: work?.assignedByUserId || user.id,
    });
  };

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          secondValue: contact?.businessContactId || "",
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes.forEach((workType) => {
        options.push({
          value: workType?.id,
          label: workType?.name,
        });
      });
    }

    return options;
  };

  const userOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (users) {
      users.forEach((user) => {
        options.push({
          value: user?.id,
          label:
            user?.userProfile?.firstName + " " + user?.userProfile?.lastName,
          avatar: getInitials(
            user?.userProfile?.firstName,
            user?.userProfile?.lastName,
          ),
          avatarColor: "blue",
        });
      });
    }

    return options;
  };

  useEffect(() => {
    if (isSuccessUpdate) {
      setIsEdit(false);
    }
  }, [isSuccessUpdate]);

  return (
    <Card extraClasses={"shadow-box"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div
            className={
              "flex justify-between border-b border-gray-300 px-6 py-4"
            }>
            <div className={"text-lg font-bold"}>Details</div>
            <div className={"flex gap-2"}>
              <Button
                label={"Cancel"}
                size={"custom"}
                colorType={"outline"}
                extraClasses={"btn-sm"}
                buttonType={"button"}
                onClick={() => setIsEdit(false)}
              />
              <Button
                label={"Save"}
                size={"custom"}
                extraClasses={"btn-sm"}
                buttonType={"submit"}
                isLoading={isLoadingUpdate}
                disabled={isLoadingUpdate}
              />
            </div>
          </div>
          <div
            className={
              "grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-4 px-6 py-4 text-base"
            }>
            <div>
              <div className={"mb-2 text-gray-400"}>CLIENT</div>
              <Controller
                name={"customer"}
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field: { value, onChange } }) => (
                  <CustomSelect
                    options={contactsOptions()}
                    value={value}
                    onChange={(newValue) => {
                      const newContact = newValue as SelectOptionType;
                      onChange(newContact);
                    }}
                    inputValue={searchContacts}
                    onChangeInput={setSearchContacts}
                    withAvatar
                    isLoading={isLoadingContacts}
                  />
                )}
              />
            </div>
            <div>
              <div className={"mb-2 text-gray-400"}>WORK TYPE</div>
              <Controller
                name={"workType"}
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field: { value, onChange } }) => (
                  <CustomSelect
                    options={workTypeOptions()}
                    value={value}
                    onChange={(newValue) => {
                      const newWorkType = newValue as SelectOptionType;
                      onChange(newWorkType);
                    }}
                    isLoading={isLoadingWorkTypes}
                  />
                )}
              />
            </div>
            <div>
              <div className={"mb-2 text-gray-400"}>ASSIGNEE</div>
              <Controller
                name={"user"}
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field: { value, onChange } }) => (
                  <CustomSelect
                    options={userOptions()}
                    value={value}
                    onChange={(newValue) => {
                      const newUser = newValue as SelectOptionType;
                      onChange(newUser);
                    }}
                    withAvatar
                    isLoading={isLoadingUsers}
                  />
                )}
              />
            </div>
            <div>
              <div className={"mb-2 text-gray-400"}>RECURRING</div>
              <div
                className={
                  "cursor-pointer text-sm font-semibold hover:underline "
                }
                onClick={() => setIsOpen(true)}>
                {repeats.find((item) => repeatsData?.repeat === item.id)
                  ?.name || "Never"}
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <Repeat
            jobId={work.id}
            work={work}
            values={repeatsData}
            close={() => {
              setIsOpen(false);
            }}
            onSubmit={(data) => {
              setIsOpen(false);
              updateWorkRepeats({
                workId: work?.id,
                repeatTypeEnumId: data?.repeat,
                beforeDays: Number(data?.days),
                repeatOnId: data?.repeatOn,
                repeatValue: data?.repeatCount,
                repeatUntil: data?.repeatUntil,
                dueDate: data?.dueDate,
              });
            }}
            onChange={setRepeatsData}
          />
        )}
      </form>
    </Card>
  );
};

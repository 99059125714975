import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { ITime, IUser, IWork } from "../types/types";
import { deleteLinkedFirm } from "../sessionStorage/sessionStorage";

export const getTimesByWork = createAsyncThunk(
  "app/getTimesByWork",
  async (
    { jobId, orgId }: { jobId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/get-by-workId/${jobId}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getAllTimes = createAsyncThunk(
  "app/getAllTimes",
  async (id: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/get-all?orgId=${id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data.sort(
        (a: any, b: any) => moment(b.date).unix() - moment(a.date).unix(),
      );
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const updateTime = createAsyncThunk(
  "app/updateTime",
  async (time: any, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/update-time`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(time),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const deleteTime = createAsyncThunk(
  "app/deleteTime",
  async (time: any, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify(time),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getAllUsers = createAsyncThunk(
  "app/getAllUsers",
  async (orgId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/users/search?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const reorderSubTaskTemplates = createAsyncThunk(
  "app/reorderSubTaskTemplates",
  async (list: string[], { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/template-task/change-order`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(list),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const reorderWorkTasks = createAsyncThunk(
  "app/reorderWorkTasks",
  async (list: string[], { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work-tasks/change-order`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
        body: JSON.stringify(list),
      },
    );
    if (response.ok) {
      return response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getTeamWorks = createAsyncThunk(
  "app/getTeamWorks",
  async (orgId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/dashboard/team-work?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return Object.keys(data.assignedUserAllOpenWork).map((name, index) => ({
        id: index,
        name,
        assignedUserCompletedCount: data.assignedUserCompletedCount[name],
        assignedUserDueLaterCount: data.assignedUserDueLaterCount[name],
        assignedUserNextWeekCount: data.assignedUserNextWeekCount[name],
        assignedUserAllOpenWork: data.assignedUserAllOpenWork[name],
        assignedUserWeekCount:
          data.assignedUserWeekCount[name] +
          data.assignedUserOverDueCount[name],
      }));
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getAllWorks = createAsyncThunk(
  "app/getAllWorks",
  async (orgId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/get-all?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data: IWork[] = await response.json();
      return data.sort((a, b) => {
        if (a?.name > b?.name) {
          return 1;
        }
        if (a?.name < b?.name) {
          return -1;
        }
        return 0;
      });
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const getUser = createAsyncThunk(
  "app/getUser",
  async (
    { userId, orgId }: { userId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/users/${userId}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const getLinkedFirms = createAsyncThunk(
  "app/getLinkedFirms",
  async (userId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-organization/get-linked-orgs/${userId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      return await response.json();
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const handleDeleteLinkFirm = createAsyncThunk(
  "app/deleteLinkedFirm",
  async (
    { userId, orgId }: { userId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/user-organization/remove-link`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "DELETE",
        body: JSON.stringify({ userId, orgId }),
      },
    );
    if (response.ok) {
      deleteLinkedFirm();
    } else {
      return rejectWithValue("Error");
    }
  },
);

interface IInitialState {
  timesByWork: ITime[];
  times: ITime[];
  users: IUser[];
  user: IUser;
  linkedFirms: { id: string; legalName: string }[];
  teamWorks: any;
  allWorks: IWork[];
  isLoading: boolean;
  error: string;
  isLinkedFirm: boolean;
}

const initialState: IInitialState = {
  isLoading: false,
  error: "",
  timesByWork: [],
  times: [],
  users: [],
  user: {} as IUser,
  teamWorks: [],
  allWorks: [],
  linkedFirms: [],
  isLinkedFirm: false,
};
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAllWorks(state, action) {
      state.allWorks = action.payload;
    },
    setIsLinkedFirm(state, action: PayloadAction<boolean>) {
      state.isLinkedFirm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimesByWork.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getTimesByWork.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timesByWork = action.payload;
      })
      .addCase(getTimesByWork.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllTimes.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getAllTimes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.times = action.payload;
      })
      .addCase(getAllTimes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTime.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(deleteTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.times = state.times.filter(
          (item) => item.id !== action.payload.id,
        );
        state.timesByWork = state.timesByWork.filter(
          (time) => time.id !== action.payload.id,
        );
      })
      .addCase(deleteTime.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(reorderSubTaskTemplates.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(reorderSubTaskTemplates.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(reorderSubTaskTemplates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(reorderWorkTasks.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(reorderWorkTasks.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(reorderWorkTasks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTeamWorks.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getTeamWorks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teamWorks = action.payload;
      })
      .addCase(getTeamWorks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllWorks.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getAllWorks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allWorks = action.payload;
      })
      .addCase(getAllWorks.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLinkedFirms.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getLinkedFirms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.linkedFirms = action.payload;
      })
      .addCase(getLinkedFirms.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(handleDeleteLinkFirm.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(handleDeleteLinkFirm.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(handleDeleteLinkFirm.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setIsLinkedFirm } = appSlice.actions;
export default appSlice.reducer;

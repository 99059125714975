import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { ValidationConstants } from "../../../../../../../../app/constants/ValidationConstants";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import CustomSelect from "../../../../../../../../components/select/Select";
import {
  useEditContactMutation,
  useGetContactTypesQuery,
} from "../../../../../../../../slices/ContactsSlice";
import {
  IContactProfile,
  SelectOptionType,
} from "../../../../../../../../types/types";

type Props = {
  contact: IContactProfile;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

type FormData = {
  firstName: string;
  middleName: string;
  lastName: string;
  socialIdentificationNumber: string;
  email: string;
  phoneNumber: string;
  contactType: SelectOptionType;
  address: {
    line1: string;
    city: string;
    region: string;
    postalCode: string;
  };
  additionalInfo: string;
};

export const ContactEditInfo: FC<Props> = ({ contact, setIsEdit }) => {
  const { data: contactTypes, isLoading } = useGetContactTypesQuery();
  const [editContact, { isSuccess, isLoading: isLoadingEdit }] =
    useEditContactMutation();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: contact?.firstName,
      middleName: contact?.middleName,
      lastName: contact?.lastName,
      socialIdentificationNumber: contact?.socialIdentificationNumber,
      email: contact?.email,
      phoneNumber: contact?.phoneNumber,
      contactType: {
        value: contact?.contactTypeEnumId,
        label: contact?.contactType,
      },
      address: {
        line1: contact?.address?.line1,
        city: contact?.address?.city,
        region: contact?.address?.region,
        postalCode: contact?.address?.postalCode,
      },
      additionalInfo: contact?.additionalInfo,
    },
  });

  const { contactNameRule } = ValidationConstants.customerRules;

  const onSubmit = (data: FormData) => {
    if (!isLoadingEdit) {
      editContact({
        ...contact,
        contactId: contact?.contactId || "",
        customerId: contact?.customerId || "",
        locationId: contact?.locationId || "",

        firstName: data.firstName || "",
        middleName: data.middleName || "",
        lastName: data.lastName || "",
        socialIdentificationNumber: data.socialIdentificationNumber || "",
        email: data.email || "",
        phoneNumber: data.phoneNumber || "",
        contactTypeEnumId: data?.contactType?.value || "",
        address: {
          line1: data?.address?.line1 || "",
          city: data?.address?.city || "",
          region: data?.address?.region || "",
          postalCode: data?.address?.postalCode || "",
        },
        additionalInfo: data?.additionalInfo || "",
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(false);
    }
  }, [isSuccess]);

  return (
    <Card extraClasses={"shadow-box"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"px-6 py-4"}>
          <div className={"mb-4 flex justify-between"}>
            <div className={"text-lg font-bold"}>Contact Info</div>
            <div className={"flex gap-2"}>
              <Button
                label={"Cancel"}
                buttonType={"button"}
                size={"custom"}
                colorType={"outline"}
                extraClasses={"btn-sm"}
                onClick={() => setIsEdit(false)}
              />
              <Button
                isLoading={isLoadingEdit}
                label={"Save"}
                disabled={isLoadingEdit}
                buttonType={"submit"}
                size={"custom"}
                extraClasses={"btn-sm"}
              />
            </div>
          </div>
          <div className={"grid-rows-[repeat(7, max-content)] grid gap-6"}>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>FIRST NAME</div>
                <input
                  placeholder={"First name"}
                  {...register("firstName", {
                    ...contactNameRule.valueLength,
                    required: "This field is required",
                    maxLength: {
                      value: 25,
                      message:
                        "First name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.firstName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.firstName?.message}
                  </div>
                )}
              </div>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  MIDDLE NAME
                </div>
                <input
                  placeholder={"Middle name"}
                  {...register("middleName", {
                    ...contactNameRule.valueLength,
                    maxLength: {
                      value: 25,
                      message:
                        "Middle name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.middleName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.middleName?.message}
                  </div>
                )}
              </div>
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>LAST NAME</div>
                <input
                  placeholder={"Last name"}
                  {...register("lastName", {
                    ...contactNameRule.valueLength,
                    required: "This field is required",
                    maxLength: {
                      value: 25,
                      message:
                        "Last name shouldn't be greater than 25 characters",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors?.lastName && (
                  <div className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.lastName?.message}
                  </div>
                )}
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>TAX ID</div>
                <input
                  placeholder={"Tax ID"}
                  {...register("socialIdentificationNumber", {
                    maxLength: {
                      value: 20,
                      message: "TAX ID shouldn't be more than 20 characters.",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors.socialIdentificationNumber && (
                  <span className="pt-2 text-sm font-bold text-error">
                    {errors.socialIdentificationNumber.message}
                  </span>
                )}
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>EMAIL</div>
                <input
                  placeholder={"Email"}
                  {...register("email", {
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  })}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                {errors.email && (
                  <span className="pt-2 text-sm font-bold text-error">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  PHONE NUMBER
                </div>
                <input
                  placeholder={"Phone number"}
                  {...register("phoneNumber")}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
              </div>
              <div />
            </div>
            <div className={"grid grid-cols-2 gap-4"}>
              <div>
                <div className={"mb-2 text-base text-gray-400"}>
                  CONTACT TYPE
                </div>
                <Controller
                  control={control}
                  name={"contactType"}
                  render={({ field: { value, onChange } }) => (
                    <CustomSelect
                      value={value}
                      options={contactTypes || []}
                      isLoading={isLoading}
                      onChange={(newValue) => {
                        const contactType = newValue as SelectOptionType;
                        onChange(contactType);
                      }}
                      small
                    />
                  )}
                />
              </div>
              <div />
            </div>
            <div className={"grid grid-rows-[repeat(4,max-content)] gap-4"}>
              <div className={"mb-2 text-base text-gray-400"}>ADDRESS</div>
              <input
                placeholder={"Street address"}
                {...register("address.line1")}
                type={"text"}
                className={"input input-bordered input-sm w-full"}
              />
              <div className={"grid grid-cols-2 gap-4"}>
                <input
                  placeholder={"City"}
                  {...register("address.city")}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                <input
                  placeholder={"State / Province"}
                  {...register("address.region")}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
              </div>
              <div className={"grid grid-cols-2 gap-4"}>
                <input
                  placeholder={"Zip / Postal Code"}
                  {...register("address.postalCode")}
                  type={"text"}
                  className={"input input-bordered input-sm w-full"}
                />
                <div />
              </div>
            </div>
            <div>
              <div className={"mb-2 text-base text-gray-400"}>
                ADDITIONAL INFO
              </div>
              <textarea
                {...register("additionalInfo")}
                className={"input input-bordered min-h-[100px] w-full"}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};

import { FC } from "react";

import { WorkDetailsModel } from "../../../../factories/work/model/work-details.model";
import { WorkDetails } from "./widgets/work-details/WorkDetails";
import { WorkFiles } from "./widgets/work-files/WorkFiles";
import { WorkMain } from "./widgets/work-main/WorkMain";
import { WorkTasks } from "./widgets/work-tasks/WorkTasks";
import { WorkNotes } from "./widgets/work-notes/WorkNotes";

type Props = {
  work: WorkDetailsModel;
};

export const WorkDetailsView: FC<Props> = ({ work }) => {
  return (
    <div className={"grid grid-rows-[repeat(2,max-content)] gap-6"}>
      <WorkMain work={work} />
      <div className={"grid grid-cols-[2fr_1fr] gap-6"}>
        <div className={"grid grid-rows-[repeat(2,max-content)] gap-6"}>
          <div className={"grid grid-cols-[repeat(2,1fr)] gap-6"}>
            <WorkDetails work={work} />
            <WorkFiles />
          </div>
          <div>
            <WorkTasks work={work} />
          </div>
        </div>
        <WorkNotes />
      </div>
    </div>
  );
};

import { Datepicker } from "baseui/datepicker";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import CustomSelect from "../../../components/select/Select";
import { getInitialsFromFullName } from "../../../constants/constants";
import { useAppSelector } from "../../../redux/redux";
import {
  IAddPayment,
  useAddPaymentMutation,
  useGetInvoicesByCustomerQuery,
  useGetPaymentMethodsQuery,
  useUpdatePaymentMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetActiveCustomersQuery } from "../../../slices/ContactsSlice";
import { FormSection } from "../../../support/FormSection";
import { SelectOptionType } from "../../../types/types";
import { transformDateToUTC } from "../../../utils/transformDate";

const AddPayment = () => {
  const [searchParams] = useSearchParams();
  const defaultCustomerId = searchParams?.get("customerId") || "";

  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);

  const [addPayment, { isSuccess: isSuccessAdd, error }] =
    useAddPaymentMutation();
  useUpdatePaymentMutation();

  const { data: paymentMethods, isLoading: isLoadingMethods } =
    useGetPaymentMethodsQuery(getLinkedFirm()?.orgId);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetActiveCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      isActive: true,
      body: [],
    });

  const [currentContact, setCurrentContact] = useState<SelectOptionType | null>(
    null,
  );

  const {
    data: invoices,
    isLoading,
    refetch,
  } = useGetInvoicesByCustomerQuery(currentContact?.value || "");

  const [data, setData] = useState<
    {
      isChecked: boolean;
      invoiceId: number;
      id: string;
      dueDate: string;
      originalAmount: number;
      balance: number;
      amountPaid: string;
      _id?: string;
    }[]
  >([]);

  const [paymentDate, setPaymentDate] = useState(new Date());
  const [method, setMethod] = useState("");
  const [notes, setNotes] = useState("");

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const createPayment = () => {
    if (currentContact?.value && paymentDate) {
      let amountPaid = 0;
      let balance = 0;
      data.forEach((item) => {
        amountPaid += +item.amountPaid || 0;
        balance += item.balance;
      });
      const body: IAddPayment = {
        paymentDate: paymentDate.toISOString(),
        customerId: currentContact?.value,
        amount: amountPaid,
        balance: balance,
        notes: notes,
        orgId: getLinkedFirm()?.orgId,
        paymentMethodId: method,
        userId: user?.id,
        paymentItem:
          data?.map((item) => ({
            invoiceId: item.id,
            amountPaid: +item.amountPaid || 0,
          })) || [],
      };
      addPayment(body);
    }
  };

  useEffect(() => {
    if (defaultCustomerId && contacts?.length) {
      const defaultCustomer: SelectOptionType | null =
        contactsOptions()?.find((item) => item.value === defaultCustomerId) ||
        null;

      setCurrentContact(defaultCustomer);
    }
  }, [contacts?.length, defaultCustomerId]);

  useEffect(() => {
    if (invoices && invoices?.length > 0) {
      setData(
        invoices?.map((item) => ({
          invoiceId: item?.invoiceNo || 0,
          id: item?.id || "",
          dueDate: item?.dueDate || "",
          originalAmount: item?.total || 0,
          balance: item?.balance || 0,
          amountPaid: "",
          isChecked: false,
        })),
      );
    }
  }, [invoices, currentContact]);

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      setMethod(paymentMethods[0].id);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (currentContact?.value) {
      refetch();
    }
  }, [currentContact]);

  useEffect(() => {
    if (isSuccessAdd) {
      navigate("/billing/payments");
    }
  }, [isSuccessAdd]);

  const total = useMemo(() => {
    let value = 0;
    data.forEach((item) => (value += +item?.amountPaid || 0));
    return value;
  }, [data]);

  if (isLoadingMethods || isLoadingContacts) {
    return <LottieLoading />;
  }

  const tableRows = data.map((item, index) => {
    return (
      <tr
        key={index}
        className={
          "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
        }>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <input
            type={"checkbox"}
            className={"checkbox checkbox-accent checkbox-sm"}
            checked={item?.isChecked}
            onChange={(e) => {
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? {
                        ...item,
                        isChecked: e?.target?.checked,
                        amountPaid: e?.target?.checked
                          ? item?.balance?.toString() || ""
                          : "",
                      }
                    : invoice,
                ),
              );
            }}
          />
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {item?.invoiceId}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {moment(item?.dueDate).format("MM/DD/YYYY")}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(item?.originalAmount || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(item.balance || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td
          className={
            "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <CustomCurrencyInput
            value={item?.amountPaid}
            setValue={(value) =>
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? {
                        ...item,
                        amountPaid: value || "",
                      }
                    : invoice,
                ),
              )
            }
          />
        </td>
      </tr>
    );
  });

  return (
    <FormSection name={"Record payment"} classForTitle={"p-0"}>
      <div>
        <div className={"mb-4"}>
          <div className={"mb-2 text-base font-semibold text-gray-800"}>
            Client
          </div>
          <div className={"w-[300px]"}>
            <CustomSelect
              placeholder={"Search client"}
              value={currentContact}
              options={contactsOptions() || []}
              onChange={(newValue) =>
                setCurrentContact({ ...(newValue as SelectOptionType) })
              }
            />
          </div>
        </div>
        <div className={"flex gap-4"}>
          <div>
            <div className={"mb-2 text-base font-semibold text-gray-800"}>
              Payment date
            </div>
            <div>
              <Datepicker
                value={paymentDate}
                overrides={{
                  Input: {
                    props: {
                      overrides: {
                        Input: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                          }),
                        },
                        Root: {
                          style: () => ({
                            borderTopWidth: "1px",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderLeftWidth: "1px",
                            borderTopColor: "#D4D6D9",
                            borderRightColor: "#D4D6D9",
                            borderBottomColor: "#D4D6D9",
                            borderLeftColor: "#D4D6D9",
                          }),
                        },
                      },
                    },
                  },
                }}
                onChange={({ date }) => {
                  if (date instanceof Date) {
                    setPaymentDate(transformDateToUTC(date));
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className={"mb-2 text-base font-semibold text-gray-800"}>
              Payment method
            </div>
            <div>
              <select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                className={"select select-bordered"}>
                {paymentMethods?.map((item) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.paymentMethod}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <table className={"w-full"}>
        <thead className={"border-b-[1px] border-gray-300"}>
          <tr>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Invoice #
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Due date
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Original amount
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case "}>
                Balance
              </div>
            </th>
            <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
              <div className={"flex whitespace-nowrap text-sm normal-case"}>
                Amount paid
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={"text-[14px]"}>
          {isLoading ? <LottieLoading /> : tableRows}
        </tbody>
      </table>
      {error && (
        <div
          className={
            "flex w-full justify-end pr-5 pt-2 text-sm font-bold text-error"
          }>
          {error}
        </div>
      )}
      <div className={"flex w-full justify-between "}>
        <div>
          <div className={"mb-2 text-base font-semibold text-gray-800"}>
            Notes
          </div>
          <textarea
            className={"textarea textarea-bordered min-w-[300px]"}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className={"text-base font-semibold text-gray-800"}>
          Total: $
          {(total || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className={"flex w-full justify-end gap-4"}>
        <Button
          label={"Cancel"}
          onClick={() => navigate(-1)}
          colorType={"ghost"}
          extraClasses={"normal-case"}
        />
        <Button
          label={"Save"}
          extraClasses={"normal-case"}
          onClick={createPayment}
        />
      </div>
    </FormSection>
  );
};

export default AddPayment;

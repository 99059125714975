import React, { FC } from "react";
import { useParams } from "react-router-dom";

import Button from "../../../../../../components/button/Button";
import { Card } from "../../../../../../components/card/Card";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { useMarkDoneAllWorkTasksMutation } from "../../../../../../slices/WorkTasksSlice";
import { WorkEditTemplate } from "./WorkEditTemplate";
import { WorkTasksList } from "./WorkTasksList";

type Props = {
  work: WorkDetailsModel;
};

export const WorkTasks: FC<Props> = ({ work }) => {
  const { workId } = useParams();
  const [markDoneAllWorkTasks, { isLoading }] =
    useMarkDoneAllWorkTasksMutation();

  return (
    <Card extraClasses={"shadow-box"}>
      <div className={"max-w-full pt-4"}>
        <div className={"mb-4 flex w-full justify-between px-6"}>
          <div className={"flex h-12 items-center gap-2"}>
            <div className={"text-lg font-bold"}>Subtasks</div>
            <WorkEditTemplate work={work} />
          </div>
          <Button
            label={"Mark all as completed"}
            onClick={() => markDoneAllWorkTasks({ workId: workId || "" })}
            size={"custom"}
            isLoading={isLoading}
            disabled={isLoading}
            extraClasses={"btn-sm px-4"}
          />
        </div>
        <WorkTasksList />
      </div>
    </Card>
  );
};

import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../../../../constants/constants";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { navigateToContact } from "../../../../../contacts/utils/navigateToContacts";
import Button from "../../../../../../components/button/Button";
import { Card } from "../../../../../../components/card/Card";
import { Repeat } from "../../../repeats/Repeats";

type Props = {
  work: WorkDetailsModel;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const WorkDetailsInfo: FC<Props> = ({ work, setIsEdit }) => {
  const navigate = useNavigate();

  return (
    <Card extraClasses={"shadow-box"}>
      <div>
        <div
          className={"flex justify-between border-b border-gray-300 px-6 py-4"}>
          <div className={"text-lg font-bold"}>Details</div>
          <Button
            label={"Edit"}
            size={"custom"}
            extraClasses={"btn-sm"}
            onClick={() => setIsEdit(true)}
          />
        </div>
        <div
          className={
            "grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-4 px-6 py-4 text-base"
          }>
          <div>
            <div className={"mb-2 text-gray-400"}>CLIENT</div>
            <div className={"h-12"}>
              <div className={"flex items-center gap-2"}>
                <AvatarPlaceholder
                  type={work?.customer?.businessContactId ? "purple" : "blue"}
                  size={"super-small"}
                  label={getInitialsFromFullName(work?.customer?.name)}
                />
                <div
                  className={
                    "link-primary cursor-pointer text-sm font-semibold hover:underline"
                  }
                  onClick={() =>
                    navigate(
                      navigateToContact({
                        type: work?.customer?.businessContactId
                          ? "organization"
                          : "contact",
                        customerId: work?.customerId || "",
                        id: work?.customer?.businessContactId || "",
                      }),
                    )
                  }>
                  {work?.customer?.name}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"mb-2 text-gray-400"}>WORK TYPE</div>
            <div className={"h-12 text-sm font-semibold"}>
              {work?.service?.name}
            </div>
          </div>
          <div>
            <div className={"mb-2 text-gray-400"}>ASSIGNEE</div>
            <div className={"h-12"}>
              <div className={"flex items-center gap-2"}>
                <AvatarPlaceholder
                  size={"super-small"}
                  label={getInitials(
                    work?.assignedUser?.userProfile?.firstName,
                    work?.assignedUser?.userProfile?.lastName,
                  )}
                />
                <div className={"text-sm font-semibold"}>
                  {work?.assignedUser?.userProfile?.firstName}{" "}
                  {work?.assignedUser?.userProfile?.lastName}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={"mb-2 text-gray-400"}>RECURRING</div>
            <div className={"h-12 w-fit text-sm font-semibold"}>
              {work?.repeatTypeEnum?.repeatType}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

import React, { FC } from "react";

type Props = {
  duration: string;
};

export const WorkTimeLog: FC<Props> = ({ duration }) => {
  return (
    <div>
      <div className={"mb-1 h-full text-base text-gray-400"}>TIME LOGGED</div>

      <div className={"h-8 text-sm font-medium"}>{duration}</div>
    </div>
  );
};

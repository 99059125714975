import React, { FC, useState } from "react";
import { WorkDetailsModel } from "../../../factories/work/model/work-details.model";
import { useNavigate } from "react-router-dom";
import { useUpdateWorkMutation } from "../../../slices/WorkSlice";
import { useAppSelector } from "../../../redux/redux";
import { LABELS } from "../../../app/constants/TextConstants";
import { IconButton } from "../../../components/button/IconButton";
import { WarningExclaimSvg } from "../../../components/svg/WarningExclaimSvg";

type Props = {
  work: WorkDetailsModel;
};

export const WorkTitle: FC<Props> = ({ work }) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.appReducer);
  const [updateWork, { isSuccess }] = useUpdateWorkMutation();

  const [workName, setWorkName] = useState(work?.name || "");
  const [isEdit, setIsEdit] = useState(false);

  const navigateToCustomer = () => {
    const isOrganization = !!work?.customer?.businessContactId;
    const path = isOrganization
      ? `/organization/${work?.customer?.businessContactId}`
      : `/contact/${work.customerId}?contactId=${work?.customer?.contactId}`;

    navigate(path);
  };

  const updateWorkName = () => {
    if (workName.trim() && workName !== work?.name) {
      updateWork({
        workId: work?.id,
        workName: workName,
        serviceId: work?.serviceId,
        taskTemplateId: work?.templateId,
        userId: work?.assignedUserId,
        customerId: work?.customerId,
        businessContactId: work?.customer?.businessContactId,
        isReassigned: work?.isReassigned,
        assignedByUserId: work?.assignedByUserId || user.id,
      });
    }
  };

  return (
    <div className={"text-3xl font-semibold text-gray-800"}>
      {isEdit ? (
        <input
          type="text"
          className={"input input-bordered"}
          value={workName}
          onChange={(e) => setWorkName(e.target.value)}
          onBlur={() => {
            setIsEdit(false);
            updateWorkName();
          }}
          autoFocus
        />
      ) : (
        <span
          className={"cursor-pointer font-notoSans hover:underline"}
          onClick={() => setIsEdit(true)}>
          {work?.name}
        </span>
      )}
      <span className={"font-notoSans"}> | </span>
      <span
        className={"cursor-pointer font-notoSans text-gray-500 hover:underline"}
        onClick={navigateToCustomer}>
        {work?.customer?.name}
      </span>
      {work.isArchived && (
        <div
          className={"tooltip tooltip-bottom tooltip-warning"}
          data-tip={LABELS.warnings.inactiveTooltip}>
          <IconButton
            size={"extra-small"}
            colorType={"secondary"}
            border={false}
            extraCx={"ml-1 mt-1 rounded-full"}
            IconSvg={WarningExclaimSvg}
          />
        </div>
      )}
    </div>
  );
};

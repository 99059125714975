import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import moment from "moment/moment";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { NotificationsCountsModel } from "../factories/notifications/models/notifications-counts.model";
import { INotification, IUser, IUserRoleEnum } from "../types/types";
import { workNotesSlice } from "./WorkNotesSlice";

export const userSlice = createApi({
  reducerPath: "userSlice",
  tagTypes: ["Notifications", "Users", "UserRoles"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUser: build.query<IUser, { userId: string; orgId: string }>({
      query: ({ userId, orgId }) => ({
        url: `api/users/${userId}`,
        params: {
          orgId: orgId,
        },
      }),
      providesTags: ["Users"],
    }),
    getUsers: build.query<IUser[], string>({
      query: (orgId) => ({
        url: "api/users/search",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: ["Users"],
    }),
    getUserRoleEnum: build.query<IUserRoleEnum[], void>({
      query: () => ({
        url: "api/user-role-enum/get-all",
      }),
      providesTags: ["UserRoles"],
    }),
    getArchivedUsers: build.query<IUser[], string>({
      query: (orgId) => ({
        url: "api/users/get-archive-users",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: ["Users"],
    }),
    archiveUser: build.mutation<unknown, IUser>({
      query: (user) => ({
        url: "api/users/archive-user",
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    unarchiveUser: build.mutation<unknown, IUser>({
      query: (user) => ({
        url: "api/users/unarchive-user",
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    addUser: build.mutation<
      unknown,
      {
        orgId: string;
        userRoleEnumId: string;
        firstName: string;
        lastName: string;
        email: string;
      }
    >({
      query: (user) => ({
        url: "api/user-invitations/add-user-invitation",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["Users"],
      transformErrorResponse: (res: FetchBaseQueryError): string => {
        const error = res?.data as string;
        return error || "";
      },
    }),
    editUser: build.mutation<unknown, IUser>({
      query: (user) => ({
        url: "api/users/update-user",
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: build.mutation<unknown, IUser>({
      query: (user) => ({
        url: "api/users",
        method: "DELETE",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),

    getCountsNotifications: build.query<
      NotificationsCountsModel,
      { userId: string; orgId: string; isRead: boolean }
    >({
      query: (params) => ({
        url: "/api/notifications/get-counts",
        params,
      }),
      providesTags: ["Notifications"],
    }),
    getUnreadNotifications: build.query<
      INotification[],
      { userId: string; orgId: string; notificationType: string }
    >({
      query: ({ userId, orgId, notificationType }) => ({
        url: "/api/notifications/get-unread-notifications",
        params: {
          userId,
          orgId,
          notificationType,
        },
      }),
      providesTags: ["Notifications"],
    }),
    getReadNotifications: build.query<
      INotification[],
      { userId: string; orgId: string; notificationType: string }
    >({
      query: ({ userId, orgId, notificationType }) => ({
        url: "/api/notifications/get-read-notifications",
        params: {
          userId,
          orgId,
          notificationType,
        },
      }),
      providesTags: ["Notifications"],
    }),
    markAsReadNotification: build.mutation({
      query: (notificationIds: string[]) => ({
        url: "/api/notifications/mark-as-read",
        method: "PUT",
        body: {
          notificationIds,
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
    markAsUnreadNotification: build.mutation({
      query: (notificationIds: string[]) => ({
        url: "/api/notifications/mark-as-unread",
        method: "PUT",
        body: {
          notificationIds,
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
    deleteNotification: build.mutation({
      query: (notificationIds: string[]) => ({
        url: "/api/notifications/delete-notification",
        method: "DELETE",
        body: {
          notificationIds,
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
    mentionNotification: build.mutation({
      query: ({
        userIds,
        userId,
        workId,
        customerId,
      }: {
        userIds: string[];
        userId: string;
        workId?: string;
        customerId?: string;
      }) => ({
        url: "/api/notifications/mention-user",
        method: "POST",
        params: {
          mentionByUser: userId,
          jobId: workId,
          customerId: customerId,
        },
        body: {
          notificationIds: userIds,
        },
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(workNotesSlice.util?.invalidateTags(["Participants"]));
        });
      },
    }),
    receiveNotifications: build.mutation<
      unknown,
      { userId: string; receiveEmail: boolean }
    >({
      query: ({ userId, receiveEmail }) => ({
        url: "api/users/receive-notification-emails",
        method: "PUT",
        params: { userId, receiveEmail },
      }),
      invalidatesTags: ["Users"],
    }),
    syncEmails: build.mutation<unknown, { userId: string; syncEmail: boolean }>(
      {
        query: ({ userId, syncEmail }) => ({
          url: "api/users/sync-contact-emails",
          method: "PUT",
          params: { userId, syncEmail },
        }),
        invalidatesTags: ["Users"],
      },
    ),
    saveTimer: build.mutation({
      query: (body) => ({
        url: "api/time/upload",
        method: "POST",
        body: { ...body, date: moment().utc() },
      }),
    }),
    updateEmail: build.mutation<unknown, { userId: string; email: string }>({
      query: ({ userId, email }) => ({
        url: "api/users/update-email-for-notification",
        method: "PUT",
        params: { userId, email },
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});
export const {
  //Users
  useGetUserQuery,
  useGetUsersQuery,
  useGetUserRoleEnumQuery,
  useGetArchivedUsersQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useArchiveUserMutation,
  useUnarchiveUserMutation,

  //Notifications
  useGetCountsNotificationsQuery,
  useGetUnreadNotificationsQuery,
  useGetReadNotificationsQuery,
  useMarkAsReadNotificationMutation,
  useMarkAsUnreadNotificationMutation,
  useDeleteNotificationMutation,
  useMentionNotificationMutation,
  useReceiveNotificationsMutation,
  useUpdateEmailMutation,

  useSyncEmailsMutation,
  useSaveTimerMutation,
} = userSlice;

import React, { FC } from "react";
import Button from "../../../../../../components/button/Button";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/redux";
import { GlobalContext } from "../../../../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import {
  timerSlice,
  updateTimer,
} from "../../../../../../services/TimerService";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { DATES } from "../../../../../../app/commonOps/CommonDateOps";

type ITimerAndStatusProps = {
  work: WorkDetailsModel;
};
export const WorkTimer: FC<ITimerAndStatusProps> = ({ work }) => {
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { setStatusTimer } = timerSlice.actions;
  const { timer } = useAppSelector((state) => state.timerReducer);

  const buttonTimerVariant = (work: WorkDetailsModel) => {
    if (
      timer.workId !== work.id &&
      (timer.timerStatus === "ended" || timer.timerStatus === null)
    ) {
      return (
        <Button
          buttonType={"button"}
          label={"Start timer"}
          size={"custom"}
          onClick={() => {
            dispatch(
              updateTimer({
                duration: 0,
                workId: work.id,
                notes: "",
                timerStatus: "started",
                assignedUserRelayId: userInfoByEmail!.id,
                contactId: work.customerId ? work.customer.contactId : null,
                businessContactId: work.customerId
                  ? null
                  : work.businessContactId,
                date: DATES.today(),
              }),
            );
            dispatch(
              setStatusTimer({
                workId: work.id,
                timerStatus: "started",
                work: { name: work.name },
                contactId: work.customerId ? work.customer.contactId : null,
                businessContactId: work.customerId
                  ? null
                  : work.businessContactId,
              }),
            );
          }}
          extraClasses={
            "normal-case min-h-fit py-0 p-0 px-4 h-8 w-[120px] max-w-[120px]"
          }
        />
      );
    }
    if (
      timer.workId === work.id &&
      (timer.timerStatus === "started" || timer.timerStatus === "paused")
    ) {
      return (
        <Button
          buttonType={"button"}
          size={"custom"}
          label={timer.timerStatus === "paused" ? "Continue" : "Pause"}
          onClick={() =>
            dispatch(
              setStatusTimer({
                timerStatus:
                  timer.timerStatus === "started" ? "paused" : "started",
              }),
            )
          }
          extraClasses={
            "normal-case min-h-fit py-0 p-0 px-4 h-8 w-[120px] max-w-[120px]"
          }
        />
      );
    } else return <></>;
  };
  return (
    <div>
      <div className={"mb-1 h-6"} />
      <div>{buttonTimerVariant(work)}</div>
    </div>
  );
};

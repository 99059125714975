import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PageLayout } from "../../../components/layouts/PageLayout";
import { CustomLink } from "../../../support/CustomLink";
import AddPayment from "../payments/AddPayment";
import PayInvoice from "../payments/PayInvoice";
import { Payments } from "../payments/Payments";
import PaymentView from "../payments/PaymentView";
import AddInvoice from "./AddInvoice";
import { InvoicesListView } from "./InvoicesListView";
import InvoiceView from "./InvoiceView";

export const InvoicesView: React.FC<unknown> = () => {
  return (
    <PageLayout title={"Billing"} extraClasses={"overflow-visible"}>
      <div className={"tabs my-8"}>
        <CustomLink to={"/billing/invoices"}>Invoices</CustomLink>
        <CustomLink to={"/billing/payments"}>Payments</CustomLink>
      </div>

      <Routes>
        <Route index element={<Navigate to={"./invoices"} replace={true} />} />
        <Route path={"invoices"} element={<Outlet />}>
          <Route index element={<InvoicesListView />} />
          <Route path={":id"} element={<InvoiceView />} />
          <Route path={"add"} element={<AddInvoice />} />
        </Route>
        <Route path={"payments"} element={<Outlet />}>
          <Route index element={<Payments />} />
          <Route path={":id"} element={<PaymentView />} />
          <Route path={"add"} element={<AddPayment />} />
          <Route path={"pay/:id"} element={<PayInvoice />} />
        </Route>
        <Route path={"open"} element={<>Todo open invoices</>} />
        <Route path={"closed"} element={<>Todo closed invoices</>} />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};

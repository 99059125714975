import React, { ChangeEvent, useState } from "react";
import { Card } from "../../../components/card/Card";
import Button from "../../../components/button/Button";
import { HiEllipsisVertical, HiPlus } from "react-icons/hi2";
import {
  useAddTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
} from "../../../slices/ContactTagsSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { Dropdown } from "../../../components/dropdown/new-dropdown/Dropdown";
import { useForm } from "react-hook-form";
import { SelectOptionType } from "../../../types/types";

export const TagsView = () => {
  const { data: tags, isFetching } = useGetTagsQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [deleteTag] = useDeleteTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const [addTag, { isLoading }] = useAddTagMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [tagUpdateValue, setTagUpdateValue] = useState("");
  const [tagId, setTagId] = useState("");
  const [error, setError] = useState("");
  const handleAddChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 20) {
      setError("Tag name shouldn`t be greater than 20 characters");
    } else {
      setError("");
    }
    setTagValue(value);
  };
  const handleUpdateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 20) {
      setError("Tag name shouldn`t be greater than 20 characters");
    } else {
      setError("");
    }
    setTagUpdateValue(value);
  };
  return (
    <div>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full pb-6 pt-4"}>
          <div className={"grid w-full grid-cols-3  items-center px-6 pb-4"}>
            <div className={"text-lg font-bold"}>Tags</div>
            <div className={"text-center text-lg font-bold"}>Count</div>
            <div className={"flex justify-end"}>
              <Button
                label={"+ Add New Tag"}
                size={"custom"}
                extraClasses={"btn-sm px-2"}
                onClick={() => setIsOpenAdd(true)}
              />
            </div>
          </div>
          <div>
            {isFetching || isLoading ? (
              <LottieLoading />
            ) : !tags?.length ? (
              <div className={"flex justify-center py-4 font-semibold"}>
                No tags
              </div>
            ) : (
              tags?.map((tag) => (
                <div
                  key={tag.id}
                  className={
                    "mb-1 grid w-full grid-cols-3 items-center justify-between border-b border-gray-300 px-6 pb-1 text-base font-semibold"
                  }>
                  <div>{tag.tagName}</div>
                  <div className={"text-center"}>{tag.counts}</div>
                  <div className={"flex justify-end"}>
                    <Dropdown
                      triggerElement={
                        <Button
                          label={""}
                          colorType={"ghost"}
                          size={"custom"}
                          icon={<HiEllipsisVertical size={25} />}
                          extraClasses={"btn-s p-0.5"}
                        />
                      }>
                      <div
                        className={
                          "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                        }>
                        <div
                          className={
                            "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                          }
                          onClick={() => {
                            setTagUpdateValue(tag.tagName);
                            setIsOpenUpdate(true);
                            setTagId(tag.id);
                          }}>
                          Edit
                        </div>
                        <div
                          className={
                            "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                          }
                          onClick={() =>
                            deleteTag({
                              tagName: tag.tagName,
                              id: tag.id,
                            })
                          }>
                          Delete
                        </div>
                      </div>
                    </Dropdown>
                  </div>

                  {isOpenUpdate && (
                    <div
                      className={
                        "absolute right-[200px] top-[200px] flex w-[250px] flex-wrap gap-2 rounded-md bg-white px-3 py-2 shadow-box"
                      }>
                      <div className={"w-full"}>
                        <input
                          type="text"
                          className={
                            "input input-bordered h-[40px] w-full px-2 py-1"
                          }
                          onChange={handleUpdateChange}
                          value={tagUpdateValue}
                        />
                        {error && (
                          <p className="text-sm font-semibold text-error">
                            {error}
                          </p>
                        )}
                      </div>
                      <div className={"flex w-full justify-end gap-2"}>
                        <Button
                          label={"Update"}
                          extraClasses={
                            "min-h-[40px] h-[40px] flex items-center !py-2"
                          }
                          onClick={() => {
                            if (!error) {
                              updateTag({
                                tagName: tagUpdateValue,
                                id: tagId,
                              });
                              setIsOpenUpdate(false);
                            }
                          }}
                        />
                        <Button
                          label={"Cancel"}
                          colorType={"outline"}
                          customSize={"px-1 py-1"}
                          extraClasses={
                            "min-h-[40px] h-[40px] flex items-center !py-2"
                          }
                          onClick={() => {
                            setIsOpenUpdate(false);
                            setTagUpdateValue("");
                            setError("");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <div
          className={
            "absolute right-[200px] top-[200px] flex w-[250px] flex-wrap gap-2 rounded-md bg-white px-3 py-2 shadow-box"
          }>
          <div className={"w-full"}>
            <input
              type="text"
              className={"input input-bordered h-[40px] w-full px-2 py-1"}
              value={tagValue}
              placeholder={"New tag name"}
              onChange={handleAddChange}
            />
            {error && (
              <p className="text-sm font-semibold text-error">{error}</p>
            )}
          </div>
          <div className={"flex w-full justify-end gap-2"}>
            <Button
              label={"Add"}
              extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
              onClick={() => {
                if (!error) {
                  addTag({ tagName: tagValue, orgId: getLinkedFirm()?.orgId });
                  setIsOpenAdd(false);
                  setTagValue("");
                }
              }}
            />
            <Button
              label={"Cancel"}
              colorType={"outline"}
              customSize={"px-1 py-1"}
              extraClasses={"min-h-[40px] h-[40px] flex items-center !py-2"}
              onClick={() => {
                setIsOpenAdd(false);
                setError("");
                setTagValue("");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

import moment from "moment";
import React, { FC } from "react";
import { IWorkNote } from "../../../../../../types/types";
import { getInitials } from "../../../../../../constants/constants";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { parseNote } from "../../../../../contacts/utils/parseNote";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import Button from "../../../../../../components/button/Button";
import { HiEllipsisVertical } from "react-icons/hi2";

type Props = {
  note: IWorkNote;
  setCurrentNote: (value: IWorkNote) => void;
  setIsOpenAdd: (isOpenAdd: boolean) => void;
  deleteContactNote: (value: IWorkNote) => void;
};

export const WorkNote: FC<Props> = ({
  note,
  setCurrentNote,
  setIsOpenAdd,
  deleteContactNote,
}) => {
  return (
    <div
      className={
        "mx-2 my-2 grid grid-cols-[max-content_1fr] gap-3 rounded-[12px] border border-gray-300 bg-gray-100 p-2"
      }>
      <div>
        <AvatarPlaceholder
          size={"super-small"}
          label={getInitials(
            note?.createdByUser?.userProfile?.firstName,
            note?.createdByUser?.userProfile?.lastName,
          )}
        />
      </div>
      <div className={"grid grid-rows-[repeat(3,max-content)] text-sm"}>
        <div
          className={
            "grid grid-cols-[repeat(2,max-content)] justify-between gap-2 text-gray-400"
          }>
          <div className={"w-full max-w-[180px] break-words"}>
            {note?.createdByUser?.userProfile?.firstName}{" "}
            {note?.createdByUser?.userProfile?.lastName}
          </div>
          <div className={"flex justify-between"}>
            {moment(note.createdAt).format("MMM DD, YYYY")}
            <Dropdown
              triggerElement={
                <Button
                  label={""}
                  colorType={"ghost"}
                  size={"custom"}
                  icon={<HiEllipsisVertical size={24} color={"black"} />}
                  extraClasses={"min-h-fit h-fit w-fit p-1"}
                />
              }>
              <div
                className={
                  "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                }>
                <div
                  onClick={() => {
                    setCurrentNote(note);
                    setIsOpenAdd(true);
                  }}
                  className={
                    "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  Edit
                </div>
                <div
                  onMouseDown={() => deleteContactNote(note)}
                  className={
                    "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                  }>
                  Delete
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className={"grid grid-cols-[1fr_32px] justify-between gap-2"}>
          <div className={"grid grid-rows-[repeat(2,max-content)]"}>
            <div className={"w-full break-all font-semibold"}>
              {note?.title || "No title"}
            </div>
            <div className={"w-full break-all text-black"}>
              {parseNote(note.note)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";
import { useParams } from "react-router-dom";

import { Card } from "../../../../../../components/card/Card";
import { formatTime } from "../../../../../../constants/constants";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { useGetWorkTasksQuery } from "../../../../../../slices/WorkTasksSlice";
import { getCompletedTasks, getWorkDays } from "../../../../utils/workCharts";
import { WorkButtons } from "./WorkButtons";
import { WorkCharts } from "./WorkCharts";
import { WorkDates } from "./WorkDates";
import { WorkStatus } from "./WorkStatus";
import { WorkTimeLog } from "./WorkTimeLog";
import { WorkTimer } from "./WorkTimer";
import moment from "moment";
import classNames from "classnames";

type Props = {
  work: WorkDetailsModel;
};

export const WorkMain: FC<Props> = ({ work }) => {
  const { workId } = useParams();

  const { data: workTasks } = useGetWorkTasksQuery({
    workId: workId || "",
  });

  const { totalDays, leftDays, dueIn } = getWorkDays(work);

  return (
    <Card extraClasses={"shadow-card overflow-visible"}>
      <div
        className={
          "relative grid grid-cols-[repeat(2,max-content)] items-center justify-between gap-8 py-6 pl-8 pr-10"
        }>
        <div
          className={
            "grid grid-cols-[repeat(2,max-content)] items-center gap-6"
          }>
          <WorkCharts
            totalTasks={workTasks?.length || 0}
            completedTasks={getCompletedTasks(workTasks || [])}
            totalDays={totalDays}
            leftDays={leftDays}
            dueIn={dueIn}
          />
          <WorkDates
            startDate={moment(work?.startDate).toDate()}
            dueDate={moment(work?.dueDate).toDate()}
            work={work}
          />
        </div>
        <div
          className={
            " grid grid-cols-[repeat(3,max-content)] items-center gap-6"
          }>
          <WorkTimeLog
            duration={
              work?.duration
                ? formatTime(work?.duration, {
                    isHours: true,
                    isMinutes: true,
                    isSeconds: false,
                  })
                : "00:00"
            }
          />
          <WorkTimer work={work} />
          <div
            className={classNames("w-[180px]", work.statusUpdatedAt && "mt-5")}>
            <WorkStatus work={work} />
            {work.statusUpdatedAt && (
              <div
                className={
                  "flex-end flex-col pt-1 text-xs italic text-gray-500"
                }>
                Since {moment(work.statusUpdatedAt).format(" MMM DD YYYY")}
              </div>
            )}
          </div>
        </div>
        <div className={"absolute right-8 top-6"}>
          <WorkButtons work={work} />
        </div>
      </div>
    </Card>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../../components/button/Button";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { useGetWorksQuery } from "../../slices/WorkSlice";
import ErrorPopup from "../contacts/modals/ErrorPopup";
import { WorkTableSort } from "../works/components/types/work-table";
import { WorkList } from "../works/components/WorkList";
import { AddWorkModal } from "../works/modals/AddWork.modal";

export const WorksByStatus: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const status = searchParams.get("statusName") || "";
  const workTypeId = searchParams.get("workType") || "all";
  const userId = searchParams.get("assignee") || "all";

  const { data: works, isFetching: isLoadingWorks } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    workStatuses: [status],
    worktypeIds: workTypeId === "all" ? [] : [workTypeId],
    userIds: userId === "all" ? [] : [userId],
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  useEffect(() => {
    setSearchParams({
      statusName: status,
      workType: workTypeId,
      assignee: userId,
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  }, []);

  if (!status) {
    return null;
  }

  return (
    <>
      <WorkList works={works || []} isLoading={isLoadingWorks} hasPages>
        <div
          className={
            "flex w-full items-center justify-between px-[32px] py-[24px] font-bold"
          }>
          <div>
            <div className={"mb-2"}>
              All Work ( {works?.[0]?.totalRecords || 0} )
            </div>
            <div
              className={
                "flex w-fit items-center rounded-[7px] border-[1px] border-gray-400 bg-white px-[10px] py-[5px] text-[12px]"
              }>
              <div className={"mr-4 "}>Filter by {status}</div>
              <div
                className={"close-popup cursor-pointer"}
                onClick={() => navigate("/dashboard")}>
                <img src={"/img/closse.png"} alt={"clear-filter-icon"} />
              </div>
            </div>
          </div>
          <Button
            buttonType={"button"}
            label={"Add work"}
            onClick={() => setIsAddWork(true)}
          />
        </div>
      </WorkList>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};

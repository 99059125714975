import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Button from "../../../../../../components/button/Button";
import {
  getInitialsFromFullName,
  inactiveStatusId,
} from "../../../../../../constants/constants";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import { useGetWorksQuery } from "../../../../../../slices/WorkSlice";
import { IOrganizationContact } from "../../../../../../types/types";
import ErrorPopup from "../../../../modals/ErrorPopup";
import { WorkTableSort } from "../../../../../works/components/types/work-table";
import { WorkList } from "../../../../../works/components/WorkList";
import { AddWorkModal } from "../../../../../works/modals/AddWork.modal";

type Props = {
  organization: IOrganizationContact;
};
export const OrganizationWorks: FC<Props> = ({ organization }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const { data: works, isFetching: isLoadingWorks } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: [organization?.customerId || ""],
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const isActive = organization?.contactStatusEnumId !== inactiveStatusId;

  useEffect(() => {
    setSearchParams({
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  }, []);

  return (
    <>
      <WorkList works={works || []} isLoading={isLoadingWorks} hasPages>
        <div
          className={
            "flex w-full items-center justify-between px-[32px] py-[24px] font-bold"
          }>
          <div>All Work ( {works?.[0]?.totalRecords || 0} )</div>
          <Button
            buttonType={"button"}
            label={"Add work"}
            onClick={() =>
              !isActive ? setIsOpenError(true) : setIsAddWork(true)
            }
          />
        </div>
      </WorkList>
      {isAddWork && (
        <AddWorkModal
          isOpen={isAddWork}
          onClose={() => setIsAddWork(false)}
          defaultContact={{
            value: organization?.businessCustomers?.[0]?.id || "",
            label: `${organization?.companyName}`,
            avatar: getInitialsFromFullName(organization?.companyName),
            avatarColor: "purple",
          }}
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};

import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../app/constants/TextConstants";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import BillingView from "./billing/BillingView";
import { EditOrgDetails } from "./OrgDetails/EditOrgDetails";
import { OrgDetailsView } from "./OrgDetails/OrgDetailsView";
import { InactiveTemplate } from "./taskTemplates/InactiveTemplate";
import { TaskTemplateDetails } from "./taskTemplates/Tasks/TaskTemplateDetails";
import { TaskTemplates } from "./taskTemplates/TaskTemplates";
import { AddUserView } from "./users/AddUserView";
import InvitedUsers from "./users/InvitedUsers/InvitedUsers";
import UsersActiveList from "./users/UsersActiveList";
import UsersArchivedList from "./users/UsersArchivedList";
import Workflow from "./workflow/Workflow";
import EditWorkType from "./workTypes/EditWorkType";
import InactiveWorkTypes from "./workTypes/InactiveWorkTypes";
import WorkTypes from "./workTypes/workTypes";
import { TagsView } from "./tags/TagsView";

export const SettingsView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Settings");
  });

  return (
    <PageLayout title={LABELS.features.settings}>
      <div className={"tabs my-8"}>
        <CustomLink to={"/settings/company"}>Company</CustomLink>
        <CustomLink to={"/settings/task-templates"}>Templates</CustomLink>
        <CustomLink to={"/settings/statuses"}>Statuses</CustomLink>
        <CustomLink to={"/settings/work-types"}>Work Type</CustomLink>
        <CustomLink to={"/settings/billing"}>Billing</CustomLink>
        <CustomLink to={"/settings/users"}>Users</CustomLink>
        <CustomLink to={"/settings/invited-users"}>Invited users</CustomLink>
        <CustomLink to={"/settings/tags"}>Tags</CustomLink>
      </div>

      <Routes>
        <Route index element={<Navigate to={"./company"} replace={true} />} />
        <Route path={"company"} element={<Outlet />}>
          <Route index element={<OrgDetailsView />} />
          <Route path={"edit"} element={<EditOrgDetails />} />
        </Route>
        <Route path={"task-templates"} element={<Outlet />}>
          <Route index element={<Navigate to={"./active"} replace={true} />} />
          <Route path={"active"} element={<TaskTemplates />} />
          <Route path={"inactive"} element={<InactiveTemplate />} />
          <Route path={":id"} element={<Outlet />}>
            <Route index element={<TaskTemplateDetails />} />
          </Route>
        </Route>
        <Route path={"statuses"} element={<Outlet />}>
          <Route index element={<Workflow />} />
        </Route>
        <Route path={"work-types"} element={<Outlet />}>
          <Route index element={<Navigate to={"./active"} replace={true} />} />
          <Route path={"active"} element={<WorkTypes />} />
          <Route path={"inactive"} element={<InactiveWorkTypes />} />
          <Route path={":id"} element={<Outlet />}>
            <Route index element={<EditWorkType />} />
          </Route>
        </Route>
        <Route path={"billing"} element={<Outlet />}>
          <Route index element={<BillingView />} />
        </Route>

        <Route path={"users"} element={<Outlet />}>
          <Route index element={<Navigate to={"./active"} replace={true} />} />
          <Route path={"active"} element={<Outlet />}>
            <Route index element={<UsersActiveList />} />
            <Route path={"add"} element={<AddUserView />} />
          </Route>
          <Route path={"archived"} element={<UsersArchivedList />} />
        </Route>
        <Route path={"invited-users"} element={<Outlet />}>
          <Route index element={<InvitedUsers />} />
        </Route>
        <Route path={"tags"} element={<Outlet />}>
          <Route index element={<TagsView />} />
        </Route>
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};

import "./Calendar.css";
import { ChevronLeft, ChevronRight } from "baseui/icon";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Calendar, DayPropGetter, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import Button from "../../components/button/Button";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { PageLayout } from "../../components/layouts/PageLayout";
import CalendarSvg from "../../components/svg/CalendarSvg";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { FormSection } from "../../support/FormSection";
import { IUser, IWorkflow, IWorkType } from "../../types/types";
import SelectFilterStatus, {
  ISubStatusWithColor,
} from "../works/components/selects/SelectFilterStatus";
import SelectFilterUsers from "../works/components/selects/SelectFilterUsers";
import SelectFilterWorkType from "../works/components/selects/SelectFilterWorkType";
import { WorkModel } from "../../factories/works/models/work.model";

enum ActivePeriod {
  Day = "day",
  Week = "week",
  Month = "month",
}

export const CalendarView = () => {
  const navigate = useNavigate();

  const [arrayFilterStatuses, setArrayFilterStatuses] = useState<
    (IWorkflow | ISubStatusWithColor)[]
  >([]);
  const [arrayFilterWorkTypes, setArrayFilterWorkTypes] = useState<IWorkType[]>(
    [],
  );
  const [arrayFilterAssignees, setArrayFilterAssignees] = useState<IUser[]>([]);
  const [activePeriod, setActivePeriod] = useState<ActivePeriod>(
    ActivePeriod.Month,
  );

  const [works, setWorks] = useState<WorkModel[]>([]);
  const [isLoadingWorks, setIsLoadingWorks] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const calendarRef = useRef<Calendar<WorkModel> | null>(null);

  const getWorks = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/work/find-work/${
        getLinkedFirm()?.orgId
      }?sortBy=Name&sortOrder=${"asc"}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "POST",
        body: JSON.stringify({
          pageNumber: "1",
          pageSize: "100",
          customerIds: [],
          workName: [],
          userIds: arrayFilterAssignees.map((item) => item.id),
          worktypeIds: arrayFilterWorkTypes.map((item) => item.id),
          workStatuses: arrayFilterStatuses.map((item) => {
            if ("name" in item) {
              return item.name;
            } else {
              return item.subStatus;
            }
          }),
          dueDates: ["BetweenDates"],
          startDates: [],
          FromDate: moment(currentDate).startOf("month").toDate(),
          ToDate: moment(currentDate).endOf("month").toDate(),
        }),
      },
    );

    if (response.ok) {
      const data: WorkModel[] = await response.json();
      setIsLoadingWorks(false);

      return data;
    } else {
      setIsLoadingWorks(false);
      return [];
    }
  };

  const getData = async () => {
    getWorks().then((res) => {
      window.scrollTo(0, 0);

      setWorks(res);
    });
  };

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      setIsLoadingWorks(true);
      getData().then();
    }
  }, [
    arrayFilterStatuses,
    arrayFilterWorkTypes,
    arrayFilterAssignees,
    getLinkedFirm()?.orgId,
    currentDate,
  ]);

  const dayPropGetter: DayPropGetter = useCallback(() => {
    if (activePeriod === ActivePeriod.Day) {
      return {
        style: {
          backgroundColor: "white",
        },
      };
    }
    return {};
  }, [activePeriod]);

  const handlePreviousDate = () => {
    if (activePeriod === ActivePeriod.Month) {
      setCurrentDate(moment(currentDate).subtract(1, "month").toDate());
    }
    if (activePeriod === ActivePeriod.Week) {
      setCurrentDate(moment(currentDate).subtract(1, "week").toDate());
    }
    if (activePeriod === ActivePeriod.Day) {
      setCurrentDate(moment(currentDate).subtract(1, "day").toDate());
    }
  };

  const handleNextDate = () => {
    if (activePeriod === ActivePeriod.Month) {
      setCurrentDate(moment(currentDate).add(1, "month").toDate());
    }
    if (activePeriod === ActivePeriod.Week) {
      setCurrentDate(moment(currentDate).add(1, "week").toDate());
    }
    if (activePeriod === ActivePeriod.Day) {
      setCurrentDate(moment(currentDate).add(1, "day").toDate());
    }
  };

  return (
    <div className={"flex justify-center"}>
      <div
        className={
          "w-fit pl-24 pr-32 xl:min-w-full xl:pl-16 xl:pr-24 2xl:min-w-[1300px] 3xl:min-w-[1500px] 4xl:min-w-[1700px]"
        }>
        <PageLayout
          title={
            <div className={"mb-6 flex items-center gap-4"}>
              <div
                className={
                  "bg-purple  flex h-12 w-12 items-center justify-center rounded-full text-white"
                }>
                <CalendarSvg />
              </div>
              <div className={"w-[230px] font-medium"}>
                {moment(currentDate).format("MMMM YYYY")}
              </div>
              <Button
                colorType={"ghost"}
                icon={<ChevronLeft size={40} />}
                label={""}
                size={"small"}
                extraClasses={"flex-nowrap py-0 px-0"}
                onClick={handlePreviousDate}
              />
              <Button
                colorType={"ghost"}
                icon={<ChevronRight size={40} />}
                label={""}
                size={"small"}
                extraClasses={"flex-nowrap py-0 px-0"}
                onClick={handleNextDate}
              />
            </div>
          }>
          <div
            className={
              "relative z-[2] flex h-fit w-full items-end justify-between"
            }>
            <div className={"flex items-end gap-4"}>
              <SelectFilterStatus
                setArrayFilter={setArrayFilterStatuses}
                values={arrayFilterStatuses}
              />
              <SelectFilterWorkType
                setArrayFilter={setArrayFilterWorkTypes}
                values={arrayFilterWorkTypes}
              />
              <SelectFilterUsers
                setArrayFilter={setArrayFilterAssignees}
                values={arrayFilterAssignees}
              />
              {Boolean(
                arrayFilterStatuses.length > 0 ||
                  arrayFilterAssignees.length > 0 ||
                  arrayFilterWorkTypes.length > 0,
              ) && (
                <Button
                  label={"Clear all"}
                  onClick={() => {
                    setArrayFilterStatuses([]);
                    setArrayFilterWorkTypes([]);
                    setArrayFilterAssignees([]);
                  }}
                  extraClasses={
                    "normal-case py-3 px-2 max-h-[42px] min-h-fit h-fit"
                  }
                  colorType={"ghost"}
                />
              )}
            </div>
            <div
              className={
                "flex h-fit items-center gap-4 rounded-[8px] border-[1px] border-gray-300 bg-white p-2"
              }>
              <Button
                label={"Day"}
                extraClasses={"normal-case w-[100px] min-h-fit h-fit py-3"}
                size={"small"}
                colorType={
                  activePeriod === ActivePeriod.Day ? "secondary" : "ghost"
                }
                onClick={() => setActivePeriod(ActivePeriod.Day)}
              />
              <Button
                label={"Week"}
                extraClasses={"normal-case w-[100px] min-h-fit h-fit py-3"}
                size={"small"}
                colorType={
                  activePeriod === ActivePeriod.Week ? "secondary" : "ghost"
                }
                onClick={() => setActivePeriod(ActivePeriod.Week)}
              />
              <Button
                label={"Month"}
                extraClasses={"normal-case w-[100px] min-h-fit h-fit py-3"}
                size={"small"}
                colorType={
                  activePeriod === ActivePeriod.Month ? "secondary" : "ghost"
                }
                onClick={() => setActivePeriod(ActivePeriod.Month)}
              />
            </div>
          </div>
          <FormSection
            name={""}
            showHeading={false}
            extraCx={"px-0 py-0 overflow-visible"}>
            {isLoadingWorks ? (
              <LottieLoading />
            ) : (
              <div className={"relative"}>
                {activePeriod === ActivePeriod.Day && (
                  <div
                    className={
                      "absolute left-0 top-0 z-10 flex h-16 w-16 flex-col items-center justify-center"
                    }>
                    <div className={"text-sm uppercase text-gray-500"}>
                      {moment(currentDate).format("ddd").toString()}
                    </div>
                    <AvatarPlaceholder
                      type={"purple"}
                      size={"extra-small"}
                      label={moment(currentDate).get("date").toString()}
                    />
                  </div>
                )}
                <Calendar
                  ref={calendarRef}
                  localizer={momentLocalizer(moment)}
                  toolbar={false}
                  view={activePeriod}
                  style={{ height: "700px" }}
                  startAccessor={(event) =>
                    new Date(event.dueDate.setHours(0, 0, 0, 0))
                  }
                  endAccessor={(event) =>
                    new Date(event.dueDate.setHours(0, 0, 0, 1))
                  }
                  titleAccessor={"workName"}
                  events={works.map((item) => ({
                    ...item,
                    dueDate: new Date(item.dueDate),
                  }))}
                  popup={true}
                  onView={(newView) => {}}
                  dayPropGetter={dayPropGetter}
                  date={currentDate}
                  onSelectEvent={(event) => {
                    navigate(`/work/${event.workId}`);
                  }}
                />
              </div>
            )}
          </FormSection>
        </PageLayout>
      </div>
    </div>
  );
};

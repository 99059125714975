import { ChevronDown } from "baseui/icon";
import classNames from "classnames";
import moment from "moment";
import React, { FC } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
  showStatus,
} from "../../../constants/constants";
import { WorkModel } from "../../../factories/works/models/work.model";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { StatusesDropdown } from "../dropdown/StatusesDropdown";
import { getStatusColor } from "../dropdown/utils";
import { WorkTableHeaderItem, workTableHeaderItems } from "./types/work-table";
import { getWorkProgress } from "./utils/getWorkProgress";

type Props = {
  works: WorkModel[];
};

type Params = {
  page: string;
  sortBy: string;
  sortOrder: string;
  "home-tab"?: string;
  statusName?: string;
  workType?: string;
  assignee?: string;
};

export const WorkTable: FC<Props> = ({ works }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || "1";
  const activeTab = searchParams.get("home-tab") || "";
  const sortBy = searchParams.get("sortBy");
  const sortOrder = searchParams.get("sortOrder");
  const statusName = searchParams.get("statusName") || "";
  const workType = searchParams.get("workType") || "";
  const assignee = searchParams.get("assignee") || "";

  const onClickSort = (item: WorkTableHeaderItem) => {
    const params: Params = {
      page,
      sortBy: item.sort,
      sortOrder: "",
      "home-tab": activeTab,
      statusName: statusName,
      workType: workType,
      assignee: assignee,
    };
    if (!activeTab) {
      delete params?.["home-tab"];
    }
    if (!statusName) {
      delete params?.statusName;
    }
    if (!workType) {
      delete params?.workType;
    }
    if (!assignee) {
      delete params?.assignee;
    }
    if (sortBy === item.sort) {
      params.sortOrder = sortOrder === "asc" ? "desc" : "asc";

      setSearchParams(params);
    } else {
      params.sortOrder = "asc";
      setSearchParams(params);
    }
  };

  const isOverdue = (work: WorkModel) => {
    return moment().startOf("day").unix() > moment(work.dueDate).unix();
  };

  if (!works?.length) {
    return (
      <div className={"mb-5 w-full text-center font-semibold"}>
        There are no work items
      </div>
    );
  }

  return (
    <table className={"w-full"}>
      <thead className={"border-b border-gray-300"}>
        <tr>
          {workTableHeaderItems.map((item) => (
            <th
              key={item.title}
              className={"p-4 text-start text-sm text-gray-500"}>
              <div
                className={classNames(
                  "flex items-center gap-2",
                  !item?.hideSort && "cursor-pointer",
                )}
                onClick={() => !item?.hideSort && onClickSort(item)}>
                <div className={"whitespace-nowrap"}>{item.title}</div>
                {!item.hideSort && (
                  <>
                    {sortBy === item.sort &&
                      (sortOrder === "asc" ? (
                        <HiChevronUp size={24} />
                      ) : (
                        <HiChevronDown size={24} />
                      ))}
                  </>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {works?.map((work) => (
          <tr
            key={work?.workId}
            onClick={() => navigate("/work/" + work?.workId)}
            className={
              "border-b border-gray-300 text-start text-sm transition-colors hover:bg-gray-100"
            }>
            <td className={"max-w-[300px] px-4 py-2"}>
              <div
                className={
                  "link-primary w-fit max-w-full cursor-pointer truncate font-semibold hover:underline"
                }>
                {work?.workName}
              </div>
            </td>
            <td className={"px-4 py-2"}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  const link = navigateToContact({
                    type: work?.businessContactId ? "organization" : "contact",
                    id: work?.businessContactId || "",
                    customerId: work?.customerId || "",
                  });
                  navigate(link);
                }}
                className={
                  "grid w-fit max-w-[300px] cursor-pointer grid-cols-[40px_max-content] items-center truncate"
                }>
                <AvatarPlaceholder
                  size={"super-small"}
                  label={getInitialsFromFullName(work?.customerName)}
                  type={work?.businessContactId ? "purple" : "blue"}
                />
                <div className={"link-primary font-semibold hover:underline"}>
                  {work?.customerName}
                </div>
              </div>
            </td>
            <td
              className={"px-4 py-2"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              {work?.jobStatus && (
                <StatusesDropdown
                  workTypeId={work?.workTypeId || ""}
                  workId={work?.workId || ""}
                  triggerElement={
                    <div
                      className={
                        "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
                      }>
                      <div
                        className={"flex h-[10px] w-[10px] rounded-full"}
                        style={{
                          backgroundColor: getStatusColor(
                            work?.jobStatus || "",
                          ),
                        }}
                      />
                      <div>{showStatus(work)}</div>
                      <ChevronDown size={16} />
                    </div>
                  }
                />
              )}
            </td>
            <td className={"px-4 py-2 font-semibold text-gray-800"}>
              {moment(work?.startDate).format("DD.MM.yyyy")}
            </td>
            <td
              className={classNames(
                "px-4 py-2 font-semibold",
                isOverdue(work) ? "text-red-500" : "text-gray-800",
              )}>
              {moment(work?.dueDate).format("DD.MM.yyyy")}
            </td>
            <td className={"w-[100px] px-4 py-2 text-center"}>
              <div>
                {getWorkProgress({
                  taskCounts: work?.taskCounts || 0,
                  completedTaskCounts: work?.completedTaskCounts || 0,
                })}
                %
              </div>
              <div className={"h-[5px] w-[100px] rounded-[10px] bg-gray-400"}>
                <div
                  style={{
                    width: `${getWorkProgress({
                      taskCounts: work?.taskCounts || 0,
                      completedTaskCounts: work?.completedTaskCounts || 0,
                    })}%`,
                  }}
                  className={"bg-green h-[5px] rounded-[10px]"}
                />
              </div>
            </td>
            <td
              className={
                "whitespace-nowrap px-4 py-2 font-semibold text-gray-800"
              }>
              {work?.repeatType}
              {work?.repeatType && " " + work?.repeats}
            </td>
            <td
              className={
                "whitespace-nowrap px-4 py-2 font-semibold text-gray-800"
              }>
              {work?.workType}
            </td>
            <td className={"px-4 py-2"}>
              <div
                className={
                  "grid w-fit max-w-[300px] grid-cols-[40px_max-content] items-center truncate"
                }>
                <AvatarPlaceholder
                  size={"super-small"}
                  label={getInitials(
                    work?.userFirstName || "",
                    work?.userLastName || "",
                  )}
                />
                <div className={"font-semibold text-gray-800"}>
                  {work?.userFirstName} {work?.userLastName}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getIndividualContacts,
  getOrganizationContacts,
} from "../../../api/api";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import CustomSelect from "../../../components/select/Select";
import { getInitialsFromFullName } from "../../../constants/constants";
import { updatePaymentStatusFactory } from "../../../factories/billing/factory-update-payment-status";
import {
  TimePeriod,
  useDetectTimePeriod,
} from "../../../hooks/useDetectTimePeriod";
import {
  Invoice,
  IPayment,
  SortEnum,
  useDeletePaymentMutation,
  useGetPaymentAmountsQuery,
  useGetPaymentsQuery,
  useGetPaymentStatusesQuery,
  useUpdatePaymentMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { SelectOptionType } from "../../../types/types";
import DropStatus from "../DropStatus";
import { Period, SelectDate } from "../SelectDate";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { ExcelExport } from "../../../utils/ExportData";

export const Payments: React.FC<unknown> = (extraClasses) => {
  const navigate = useNavigate();

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetPaymentStatusesQuery(getLinkedFirm()?.orgId || "");

  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [activePaymentId, setActivePaymentId] = useState("");
  const [filter, setFilter] = useState<Period>({
    period: TimePeriod.LastYear,
    from: null,
    to: null,
  });

  const { from, to } = useDetectTimePeriod(
    filter.period
      ? {
          period: filter.period,
          from: filter.from,
          to: filter.to,
        }
      : null,
  );

  const [sort, setSort] = useState<{ name: SortEnum; order: boolean }>({
    name: SortEnum.PaymentDate,
    order: false,
  });

  const [contacts, setContacts] = useState<SelectOptionType[]>([]);

  const [filterByContact, setFilterByContact] = useState<SelectOptionType[]>(
    [],
  );
  const [filterByLabel, setFilterByLabel] = useState<SelectOptionType[]>([]);

  const labels: SelectOptionType[] =
    statuses?.map((item) => ({
      label: item?.name,
      value: item?.id,
      color: item?.color,
    })) || [];

  const fetchData = async () => {
    const contactsIndividual = await getIndividualContacts(
      true,
      `${getLinkedFirm()?.orgId}`,
    );
    const businessContact = await getOrganizationContacts(
      true,
      `${getLinkedFirm()?.orgId}`,
    );

    setContacts([
      ...contactsIndividual.map((item) => ({
        label: `${item?.firstName} ${item?.lastName}`,
        value: item.customers[0].id,
        avatar: `${item?.firstName?.[0]}${item?.lastName?.[0]}`,
      })),
      ...businessContact.map((item) => ({
        label: item.companyName,
        avatar: `${item?.companyName?.[0]}`,
        avatarColor: "purple",
        value: item.businessCustomers[0].id,
      })),
    ]);
  };

  const isShowClear = useMemo(() => {
    return (
      !!filterByContact?.length ||
      !!filterByLabel?.length ||
      !(filter?.period === TimePeriod.LastYear)
    );
  }, [filter, filterByContact]);

  useEffect(() => {
    if (getLinkedFirm()?.orgId) {
      //setIsLoadingContacts(true);
      //fetchData().then(() => setIsLoadingContacts(false));
    }
  }, [getLinkedFirm()?.orgId]);

  const {
    data: payments,
    isLoading,
    isFetching,
  } = useGetPaymentsQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: ["Custom"],
    fromDate: from ? from.toISOString() : null,
    toDate: to ? to.toISOString() : null,
    sortBy: sort.name,
    sortOrder: sort.order ? "asc" : "desc",
    filterByStatus: [],
    filterByClient: filterByContact?.map((item) => item?.value),
    filterByAssignee: [],
    filterByLabel: filterByLabel?.map((item) => item?.value),
  });

  const [updatePayment, { isLoading: isLoadingUpdate }] =
    useUpdatePaymentMutation();

  const updateStatus = (statusId: string, payment: IPayment) => {
    const body = updatePaymentStatusFactory.createModelFromDto({
      payment,
      status: statusId || null,
    });
    updatePayment(body);
  };

  const { data: amounts } = useGetPaymentAmountsQuery(getLinkedFirm()?.orgId);

  const handleSort = (name: SortEnum) => {
    if (name === sort.name) {
      setSort({ name, order: !sort.order });
    } else {
      setSort({ name, order: true });
    }
  };

  const [deletePayment] = useDeletePaymentMutation();

  if (isLoading || isLoadingContacts || isLoadingStatuses) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"flex justify-start gap-6"}>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>YTD Revenue</div>
          <div className={"text-2xl font-bold"}>
            $
            {(amounts?.totalPayments || 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
      <FormSection
        name={""}
        showHeading={false}
        extraCx={"pb-0 px-0 overflow-visible"}>
        <div className={"mb-4 flex items-end justify-between px-6"}>
          <div className={"flex gap-4 "}>
            <div>
              <div
                className={
                  "mb-1 text-sm font-semibold normal-case text-gray-800"
                }>
                Client
              </div>
              <div className={"min-w-[200px]"}>
                <CustomSelect
                  placeholder={"Search client"}
                  value={filterByContact}
                  isMulti
                  options={contacts}
                  onChange={(newValue) =>
                    setFilterByContact(newValue as SelectOptionType[])
                  }
                />
              </div>
            </div>
            <div className={"flex flex-col"}>
              <div
                className={
                  "mb-1 text-sm font-semibold normal-case text-gray-800"
                }>
                Payment Date
              </div>
              <div className={"min-w-[200px]"}>
                <SelectDate
                  setValue={(value) => setFilter(value)}
                  value={filter}
                />
              </div>
            </div>
            <div>
              <div className={"flex flex-col"}>
                <div
                  className={
                    "mb-1 text-sm font-semibold normal-case text-gray-800"
                  }>
                  Label
                </div>
                <div className={"min-w-[200px]"}>
                  <CustomSelect
                    placeholder={"Search label"}
                    value={filterByLabel}
                    isMulti
                    options={labels}
                    onChange={(newValue) =>
                      setFilterByLabel(newValue as SelectOptionType[])
                    }
                  />
                </div>
              </div>
            </div>
            {isShowClear && (
              <div>
                <div className={"h-6"} />
                <Button
                  buttonType={"button"}
                  colorType={"ghost"}
                  onClick={() => {
                    setFilter({
                      period: TimePeriod.LastYear,
                      from: null,
                      to: null,
                    });
                    setFilterByContact([]);
                  }}
                  label={"Clear all"}
                  extraClasses={"normal-case"}
                />
              </div>
            )}
          </div>
          <div className={"flex gap-2"}>
            <div className={"flex items-center"}>
              <ExcelExport
                data={
                  payments?.map((payment) => ({
                    Contact: payment?.customer?.name,
                    Amount: payment?.amount,
                    Method: payment?.method,
                    "Payment Date": moment(payment?.paymentDate).format(
                      "MM/DD/YYYY",
                    ),
                    Label: payment?.paymentStatus?.name || "",
                    Notes: payment?.notes || "",
                  })) || []
                }
                fileName={"Payments"}
              />
            </div>
            <Button
              buttonType={"button"}
              onClick={() => navigate("./add")}
              label={"New payment"}
              extraClasses={"normal-case"}
            />
          </div>
        </div>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr>
              <th className={"py-4 pl-6 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.Customer)}>
                  <div>Contact</div>
                  {sort?.name === SortEnum.Customer && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.PaymentDate)}>
                  <div>Payment date</div>
                  {sort?.name === SortEnum.PaymentDate && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.Amount)}>
                  <div>Amount</div>
                  {sort?.name === SortEnum.Amount && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div
                  className={
                    "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                  }
                  onClick={() => handleSort(SortEnum.PaymentMethod)}>
                  <div>Method</div>
                  {sort?.name === SortEnum.PaymentMethod && (
                    <div className={"relative left-0 top-[-4px]"}>
                      {sort?.order ? (
                        <div className={"absolute"}>
                          <ChevronUp size={26} />
                        </div>
                      ) : (
                        <div className={"absolute"}>
                          <ChevronDown size={26} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div className={"flex whitespace-nowrap text-sm normal-case"}>
                  <div>Label</div>
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                <div className={"flex whitespace-nowrap text-sm normal-case"}>
                  <div>Notes</div>
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
            </tr>
          </thead>
          <tbody className={"text-sm"}>
            {payments?.map((payment) => (
              <tr
                key={payment.id}
                className={
                  "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                }
                onClick={() => navigate("/billing/payments/" + payment?.id)}>
                <td className={"bg-transparent px-[24px] py-[8px]"}>
                  <div className={"flex items-center"}>
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"extra-small"}
                        type={
                          payment?.customer?.businessContactId
                            ? "purple"
                            : "blue"
                        }
                        label={getInitialsFromFullName(
                          payment?.customer?.name || "",
                        )}
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            navigateToContact({
                              type: payment?.customer?.businessContactId
                                ? "organization"
                                : "contact",
                              customerId: payment?.customer?.id || "",
                              id: payment?.customer?.businessContactId || "",
                            }),
                          );
                        }}
                        className={
                          "link-primary cursor-pointer pl-2 text-sm font-semibold hover:underline"
                        }>
                        {payment?.customer?.name}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  {moment(payment?.paymentDate).format("MM/DD/YYYY")}
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  {"$" +
                    (payment?.amount || 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td
                  className={
                    "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  {payment?.paymentMethod?.paymentMethod}
                </td>
                <td className={"bg-transparent px-4 py-2"}>
                  <DropStatus
                    invoiceStatus={null}
                    isLoading={
                      (isLoadingUpdate || isFetching) &&
                      payment?.id === activePaymentId
                    }
                    paymentStatus={payment?.paymentStatus || null}
                    statuses={statuses || []}
                    selectStatus={(status) => {
                      setActivePaymentId(payment?.id);
                      updateStatus(status?.id || "", payment);
                    }}
                  />
                </td>
                <td
                  className={
                    "max-w-[300px] truncate whitespace-nowrap bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                  }>
                  {payment?.notes}
                </td>
                <td className={"bg-transparent px-4 py-2"}>
                  <EditDots
                    extraPosition={"top-[calc(100%+4px)] right-0 w-fit"}>
                    <div>
                      <div
                        className={
                          "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          deletePayment(payment);
                        }}>
                        Delete
                      </div>
                    </div>
                  </EditDots>
                </td>
              </tr>
            ))}
            <tr className={"sticky bottom-0 left-0 bg-gray-100"}>
              <td className={"px-4 py-2"}></td>
              <td
                className={
                  "px-4 py-2 text-end text-sm font-bold text-gray-800"
                }>
                Total:
              </td>
              <td className={"px-4 py-2 text-sm font-bold text-gray-800"}>
                $
                {(payments?.[0]?.sumOfAmount || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
            </tr>
          </tbody>
        </table>
      </FormSection>
    </>
  );
};

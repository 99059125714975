import React from "react";
import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";
import billingSvg from "./billing.svg";

const BillingSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md}` } = props;
  return (
    <>
      <img
        src={billingSvg}
        alt="billing"
        className={`inline-block h-[24px] w-[24px] ${sizeCx}  fill-current stroke-current`}
      />
    </>
  );
};

export default BillingSvg;

import moment from "moment";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { StyledDatePicker } from "../../../../../../components/datepicker/datepicker";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { useUpdateWorkDatesMutation } from "../../../../../../slices/WorkSlice";

type Props = {
  startDate: Date;
  dueDate: Date;
  work: WorkDetailsModel;
};

export const WorkDates: FC<Props> = ({ startDate, dueDate, work }) => {
  const [updateWorkDates, { isSuccess, isError, data: updatedWork }] =
    useUpdateWorkDatesMutation();

  const [previewDates, setPreviewDates] = useState<{
    startDate: Date;
    dueDate: Date;
  } | null>(null);

  const defaultValues = {
    workId: work?.id,
    createdAt: work?.createdAt,
    relayId: work?.relayId,
  };

  const onChangeStartDate = (date: Date) => {
    setPreviewDates({ startDate: moment(date).toDate(), dueDate: dueDate });
    updateWorkDates({
      ...defaultValues,
      startDate: moment(date).toDate(),
      endDate: work?.dueDate,
    });
  };

  const onChangeDueDate = (date: Date) => {
    setPreviewDates({ startDate: startDate, dueDate: moment(date).toDate() });
    updateWorkDates({
      ...defaultValues,
      startDate: work?.startDate,
      endDate: moment(date).toDate(),
    });
  };

  const showDates = (): { startDate: Date; dueDate: Date } => {
    if (
      moment(updatedWork?.startDate).toDate() === startDate &&
      moment(updatedWork?.dueDate).toDate() === dueDate
    ) {
      return { startDate, dueDate };
    }
    if (previewDates) {
      return previewDates;
    }
    if (updatedWork) {
      return {
        startDate: moment(updatedWork?.startDate).toDate(),
        dueDate: moment(updatedWork?.dueDate).toDate(),
      };
    }
    return { startDate, dueDate };
  };

  useEffect(() => {
    if (isSuccess) {
      setPreviewDates(null);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setPreviewDates(null);
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  return (
    <div className={"flex gap-2"}>
      <div>
        <div className={"mb-2 text-base text-gray-400"}>START DATE</div>
        <div>
          <StyledDatePicker
            value={showDates().startDate}
            onChange={onChangeStartDate}
            maxDate={showDates().dueDate}
            styleType={"ghost"}
          />
        </div>
      </div>
      <div>
        <div className={"mb-2 text-base text-gray-400"}>DUE DATE</div>
        <div>
          <StyledDatePicker
            value={showDates().dueDate}
            onChange={onChangeDueDate}
            minDate={showDates().startDate}
            styleType={"ghost"}
          />
        </div>
      </div>
    </div>
  );
};

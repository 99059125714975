import { PagesEnum } from "./types";
import { WorkDetailsView } from "../../../features/work/pages/details/WorkDetailsView";
import { WorkRepeats } from "../../../features/work/pages/repeats/WorkRepeats";
import { WorkNotes } from "../../../features/work/pages/notes/WorkNotes";
import { WorkTime } from "../../../features/work/pages/time/WorkTime";
import { WorkEmails } from "../../../features/work/pages/emails/WorkEmails";

export enum WorkPagesEnum {
  Details = "details",
  Time = "time",
  Notes = "notes",
  Repeats = "repeats",
  Emails = "emails",
}

export const WorkPages = {
  path: PagesEnum.Work,
  label: "Work",
  component: null,
  children: {
    details: {
      path: WorkPagesEnum.Details,
      label: "Details",
      component: WorkDetailsView,
    },
    time: {
      path: WorkPagesEnum.Time,
      label: "Time",
      component: WorkTime,
    },
    notes: {
      path: WorkPagesEnum.Notes,
      label: "Notes",
      component: WorkNotes,
    },
    repeats: {
      path: WorkPagesEnum.Repeats,
      label: "Repeats",
      component: WorkRepeats,
    },
    emails: {
      path: WorkPagesEnum.Emails,
      label: "Email",
      component: WorkEmails,
    },
  },
};

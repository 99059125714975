import React from "react";

import Button from "./button/Button";

interface INoItemsProps {
  icon: JSX.Element;
  message: string;
  addButtonMessage: string;
  openAdd: () => void;
  height?: string;
  isGroup?: boolean;
}
const NoItems: React.FC<INoItemsProps> = ({
  icon,
  message,
  addButtonMessage,
  openAdd,
  height = "215px",
  isGroup,
}) => {
  return (
    <div
      style={{ height: height }}
      className={`flex flex-col items-center justify-center gap-3 rounded-[8px] border-[1px] border-[#E8E9EA]`}>
      {isGroup ? (
        <div className={"flex flex-col items-center gap-3"}>
          <div
            className={
              "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#7C66F014]"
            }>
            {icon}
          </div>
          <div className={"text-[16px] font-[500] text-[#1F2937]"}>
            {message}
          </div>
          <div>
            <Button
              buttonType={"button"}
              label={addButtonMessage}
              onClick={openAdd}
              extraClasses={"normal-case"}
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#7C66F014]"
            }>
            {icon}
          </div>
          <div className={"text-[16px] font-[500] text-[#1F2937]"}>
            {message}
          </div>
          <div>
            <Button
              buttonType={"button"}
              label={addButtonMessage}
              onClick={openAdd}
              extraClasses={"normal-case"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NoItems;

import { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useGetOrgQuery } from "../../../services/OrgService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { CustomLink } from "../../../support/CustomLink";
import { GetPageTitle } from "../../../support/ScrollToTop";
import HomeTeamWork from "../HomeTeamWork";
import { HomeFirmWorks } from "./HomeFirmWorks";
import { HomeMyWorks } from "./HomeMyWorks";
import { HomeUserWorks } from "./HomeUserWorks";

export const HomeView: FC<unknown> = () => {
  const { data: org, isLoading: isLoadingOrg } = useGetOrgQuery(
    getLinkedFirm()?.orgId || "",
  );

  useEffectOnce(() => {
    document.title = GetPageTitle("Home");
  });

  if (isLoadingOrg) {
    return <LottieLoading />;
  }

  if (!org) {
    return null;
  }

  return (
    <div>
      <div className={"mb-6 flex"}>
        <CustomLink to={"/home/my-work"}>My work</CustomLink>
        <CustomLink to={"/home/firm-work"}>{"Firm's work"}</CustomLink>
        <CustomLink to={"/home/team-work"}>{"Team's work"}</CustomLink>
      </div>
      <div className={"mb-6 text-xl font-semibold"}>{org?.legalName}</div>
      <Routes>
        <Route path={""} element={<Outlet />}>
          <Route
            index
            element={<Navigate to={"/home/my-work"} replace={true} />}
          />
          <Route path={"/my-work"} element={<HomeMyWorks />} />
          <Route path={"/firm-work"} element={<HomeFirmWorks />} />
          <Route path={"/team-work"} element={<HomeTeamWork />} />
          <Route path={"/user-work/:userId"} element={<HomeUserWorks />} />
        </Route>
      </Routes>
    </div>
  );
};

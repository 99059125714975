import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { AddUserForm } from "../../../generated/operation-result-types";
import {
  useAddUserMutation,
  useGetUserRoleEnumQuery,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const AddUserView: React.FC<unknown> = () => {
  const { data: userRoleEnum, isLoading } = useGetUserRoleEnumQuery();
  const [addUser, { isSuccess, error }] = useAddUserMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Add user");
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddUserForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      userRole: 0,
    },
  });
  const onSubmit: SubmitHandler<AddUserForm> = async (data) => {
    addUser({
      orgId: getLinkedFirm()?.orgId || "",
      userRoleEnumId: userRoleEnum?.[data.userRole].id || "",
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("../");
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LottieLoading />;
  }

  const roles = userRoleEnum?.map((item, index) => {
    return (
      <option key={item.id} value={index}>
        {item.userRole}
      </option>
    );
  });
  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add user</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"User detail"} weightTitle={"bold"}>
          <div className={"form-control mb-8 flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>First name</span>
              </label>
              <input
                type={"text"}
                {...register("firstName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.firstName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.firstName?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Last name</span>
              </label>
              <input
                type={"text"}
                {...register("lastName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.lastName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.lastName?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"form-control mb-8"}>
            <label className={"label"}>
              <span className={"label-text"}>Email</span>
            </label>
            <input
              type={"text"}
              {...register("email", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Invalid email format",
                },
              })}
              className={"input input-bordered"}
            />
            {(errors.email?.message || error) && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.email?.message || error}
              </div>
            )}
          </div>
          <div className={"form-control mb-8 w-1/2"}>
            <label className={"label"}>
              <span className={"label-text"}>Role</span>
            </label>
            <select
              {...register("userRole", {
                required: true,
              })}
              className={"select select-bordered w-full"}>
              {roles}
            </select>
            {errors.userRole?.message && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.userRole?.message}
              </div>
            )}
          </div>
        </FormSection>

        <div className={"flex justify-start space-x-4 pb-4"}>
          <Button
            colorType={"secondary"}
            label={"Add user"}
            buttonType={"submit"}
            extraClasses={"normal-case"}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
            extraClasses={"normal-case"}
          />
        </div>
      </form>
    </>
  );
};

import classNames from "classnames";
import React, { FC, useState } from "react";
import { HiXMark } from "react-icons/hi2";

import Button from "../../../components/button/Button";
import CustomSelect from "../../../components/select/Select";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetStatusesQuery } from "../../../slices/StatusesSlice";
import { useGetSearchWorksQuery } from "../../../slices/WorkSlice";
import { SelectOptionType } from "../../../types/types";
import { Filters } from "../pages/AllWorks";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { useDebounce } from "usehooks-ts";

type Props = {
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  filters: Filters;
};

export const MyWorksFilters: FC<Props> = ({ setFilters, filters }) => {
  const [searchWorks, setSearchWorks] = useState("");
  const [searchClients, setSearchClients] = useState("");

  const debounceSearchWork = useDebounce(searchWorks, 300);
  const debounceSearchClient = useDebounce(searchClients, 300);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: debounceSearchClient,
      orgId: getLinkedFirm()?.orgId || "",
    });

  const { data: works, isLoading: isLoadingWorks } = useGetSearchWorksQuery({
    query: debounceSearchWork,
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });

  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id || "",
          label: `${contact.name} `,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }
    return options;
  };

  const statusesOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (statuses) {
      statuses?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
          color: item?.color,
          isBold: true,
        });
        if (item?.workTypeSubStatuses && !!item?.workTypeSubStatuses?.length) {
          item?.workTypeSubStatuses?.forEach((subItem) => {
            options.push({
              label: subItem?.subStatus,
              value: subItem?.id,
              color: item?.color,
            });
          });
        }
      });
    }
    return options;
  };

  const clearValues = () => {
    setFilters({
      customerIds: [],
      workName: [],
      userIds: [],
      workStatuses: [],
      worktypeIds: [],

      startDates: [],
      dueDates: [],
      fromDate: null,
      toDate: null,
    });
  };

  const isShowClearButton =
    !!filters?.customerIds.length ||
    !!filters?.workName.length ||
    !!filters?.workStatuses.length ||
    !!filters?.startDates?.length ||
    !!filters?.dueDates?.length;

  return (
    <div
      className={classNames(
        "grid w-full items-end gap-4",
        isShowClearButton
          ? "grid-cols-[repeat(4,max-content)]"
          : "grid-cols-[repeat(3,max-content)]",
      )}>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Client</div>
        <CustomSelect
          options={clientOptions()}
          value={filters?.customerIds}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({ ...prev, customerIds: value }));
          }}
          inputValue={searchClients}
          onChangeInput={setSearchClients}
          isMulti
          isLoading={isLoadingContacts}
        />
      </div>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Work name</div>
        <CustomSelect
          options={works || []}
          value={filters?.workName}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({ ...prev, workName: value }));
          }}
          inputValue={searchWorks}
          onChangeInput={setSearchWorks}
          isMulti
          removeSelectIcon
          isLoading={isLoadingWorks}
          placeholder={"Search..."}
        />
      </div>
      <div className={"min-w-[200px]"}>
        <div className={"mb-0.5 text-base"}>Status</div>
        <CustomSelect
          options={statusesOptions()}
          value={filters?.workStatuses}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilters((prev) => ({ ...prev, workStatuses: value }));
          }}
          isMulti
          isLoading={isLoadingStatuses}
        />
      </div>
      {isShowClearButton && (
        <div>
          <Button
            label={"Clear"}
            onClick={clearValues}
            colorType={"ghost"}
            icon={<HiXMark size={20} />}
          />
        </div>
      )}
    </div>
  );
};
